import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { IMaskInput } from "react-imask";

import dynamicStyle from "./styles";
import { Close, Save } from "@mui/icons-material";

const CreateAlertUser = (props) => {
  const { open, onClose, newUser, handleInputChange, handleAddUser } = props;

  const isAddButtonDisabled =
    !newUser.name || !newUser.email || !newUser.phoneNo;

  const classes = dynamicStyle();

  return (
    <Dialog
      open={open}
      hideBackdrop={true}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "730px",
          },
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        Add Alert User
        <IconButton onClick={onClose}>
          <Close style={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          value={newUser.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          name="email"
          label="Email"
          value={newUser.email}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <TextField
          name="phoneNo"
          label="Phone Number"
          value={newUser.phoneNo}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          autoComplete="off"
          InputProps={{
            inputComponent: IMaskInput,
            inputProps: {
              mask: "+1 (000) 000-0000",
            },
          }}
        />
        <DialogActions>
          <Button
            className={classes.deleteButton}
            style={{ backgroundColor: "#ed342a", color: "white" }}
            onClick={onClose}
            variant="contained"
          >
            <Close fontSize="small" className={classes.iconsStyle} /> Cancel
          </Button>
          <Button
            disabled={isAddButtonDisabled}
            onClick={handleAddUser}
            color="primary"
            variant="contained"
          >
            <Save fontSize="small" className={classes.iconsStyle} /> Add
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAlertUser;
