import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  iconsTitle: {
    color: "gray",
    marginLeft: "15px",
  },
  icons: {
    marginRight: "5px",
  },
}));

export default dynamicStyle;
