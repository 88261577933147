import { api } from "../../utils";
import { CREATE_COMPNAY, DELETE_COMPANY, GET_COMPANIES } from "../ApiConstants";

class CompanyApi {
  static sharedInstance = new CompanyApi();
  constructor() {
    if (CompanyApi.sharedInstance != null) {
      return CompanyApi.sharedInstance;
    }
  }

  // Get Companes  ...
  async getCompanies() {
    try {
      const response = await api.get(GET_COMPANIES);
      const { data, message, success } = response.data;
      if (success) {
        return data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
  //   Create New Company ..
  async createCompany(body) {
    try {
      const response = await api.post(CREATE_COMPNAY, body);
      const { success, message } = response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // delete company ..
  async deleteCompany(id) {
    const response = await api.delete(`${DELETE_COMPANY}${id}`);
    const { success, data, message } = response.data;
    if (success) {
      return response.data;
    } else {
      return message;
    }
  }
}

export default CompanyApi.sharedInstance;
