import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import useMediaQuery from "@mui/material/useMediaQuery";

import LocalStorage from "../managers/LocalStorage";
import { useAuth } from "../hooks/useAuth";

import {
  StoreList,
  SignIn,
  PageNotFound,
  Navbar,
  SignUp,
  GlobalSearch,
  Logs,
  ContractLogs,
  DraftList,
  SideDrawer,
  Setting,
  ContractDetail,
  ArchivedStoreList,
} from "../views";

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Routing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen);

  const { isLoggedIn } = useAuth();

  // For Protecting Routes if user not logged in..
  const AuthenticatedRoute = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    return children;
  };

  // if user logged in then not show login and singup page ..
  // If User Authenticated
  const Authenticated = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Check user isLogged in or not for hide and show side bar
  React.useEffect(() => {
    if (!isLoggedIn) {
      setOpen(false);
    } else {
      setOpen(!isSmallScreen);
    }
  }, [isLoggedIn]);

  return (
    <>
      <Router>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            {/* Top Bar  */}
            <Navbar
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              setOpen={setOpen}
            />
          </AppBar>
          {/* Left Drawer */}
          <SideDrawer
            setOpen={setOpen}
            open={open}
            handleDrawerClose={handleDrawerClose}
            theme={theme}
            isSmallScreen={isSmallScreen}
          />

          {/* Main Section  */}
          <Main
            open={open}
            sx={{ marginTop: "50px", marginLeft: isLoggedIn ? null : 0 }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <AuthenticatedRoute>
                    <StoreList />
                  </AuthenticatedRoute>
                }
              />

              {/* careers@viralsquare.org */}
              <Route
                path="/search"
                element={
                  <AuthenticatedRoute>
                    <GlobalSearch />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/logs"
                element={
                  <AuthenticatedRoute>
                    <Logs />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/ArchivedStoreList"
                element={
                  <AuthenticatedRoute>
                    <ArchivedStoreList />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/drafts"
                element={
                  <AuthenticatedRoute>
                    <DraftList />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/contract-logs"
                element={
                  <AuthenticatedRoute>
                    <ContractLogs />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/setting"
                element={
                  <AuthenticatedRoute>
                    <Setting />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/contract/:id"
                element={
                  <AuthenticatedRoute>
                    <ContractDetail setOpen={setOpen} />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <Authenticated>
                    <SignUp />
                  </Authenticated>
                }
              />
              <Route
                path="/signin"
                element={
                  <Authenticated>
                    <SignIn />
                  </Authenticated>
                }
              />
              <Route path="*" exact={true} element={<PageNotFound />} />
            </Routes>
          </Main>
        </Box>
      </Router>
    </>
  );
};

export default Routing;
