import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, IconButton, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useQuery } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

import { useNavigate } from "react-router-dom";

import ContractApi from "../../services/Api/Contract.Api";
import { UpdateDraftDialog } from "./components";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../components";

const DraftList = () => {
  // Get draft contracts list ..
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [draftID, setDraftID] = useState(null);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [columnIndex, setColumnIndex] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { data: draftContracts, refetch: refetchDrafts } = useQuery(
    "GET_DRAFT_CONTRACTS",
    () => ContractApi.getDraftContracts()
  );

  // Mui table columns ..
  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "companyName",
      label: "Company Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "contractPerson",
      label: "Company Contact",
    },
    {
      name: "companyAdvisorEmail",
      label: "Contact Email",
    },
    {
      name: "phoneNo",
      label: "Contact Phone",
    },
    {
      name: "storeName",
      label: "Store Name",
    },
    {
      name: "servicesDetails",
      label: "Services Details",
    },
    {
      name: "notes",
      label: "Notes",
      options: {
        customBodyRender: (value) => {
          return value.length > 15 ? `${value.substring(0, 15)}...` : value;
        },
      },
    },
    {
      name: "paymentFrequency",
      label: "Payment Type",
    },
    {
      name: "monthlyPayment",
      label: "Payment Amount",
    },
    {
      name: "startedDate",
      label: "Start Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value ? "" : moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "expiryDate",
      label: "Expiry Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value ? "" : moment(value).format("DD-MM-YYYY");
        },
      },
    },

    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          let columnIndex = tableMeta.rowIndex + 1;
          return (
            <>
              <Grid sx={{ display: "flex", flexDirection: "row" }}>
                {/* Delete Icons */}
                <IconButton onClick={() => handleConfirmOpen(id)}>
                  <DeleteIcon sx={{ color: "#e53935" }} />
                </IconButton>
                {/* Update draft */}
                <IconButton
                  onClick={() => {
                    updateDialogOpen(id, columnIndex);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",
  };

  const handleDelete = async () => {
    const response = await ContractApi.deleteDraft(draftID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      refetchDrafts();
    }
  };

  const handleConfirmOpen = (id) => {
    setDraftID(id);
    setConfirmOpen(true);
  };
  const updateDialogOpen = (id, indexVal) => {
    setOpen(true);
    setDraftID(id);
    setColumnIndex(indexVal);
  };

  return (
    <Grid sx={{ mt: 1 }}>
      {draftContracts && draftContracts.length > 0 && (
        <Grid sx={{ my: 3 }}>
          <MUIDataTable
            title={"Draft list"}
            data={draftContracts}
            columns={columns}
            options={options}
          />
        </Grid>
      )}
      {/* Update draft dialog */}
      <UpdateDraftDialog
        open={open}
        setOpen={setOpen}
        draftID={draftID}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
        refetchDrafts={refetchDrafts}
        columnIndex={columnIndex}
        setColumnIndex={setColumnIndex}
      />
      {/* Delete Confirmation Dialog  */}
      <ConfirmDialog
        title="Delete Draft ?"
        dialogContext="Are you sure to delete draft"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
    </Grid>
  );
};

export default DraftList;
