import React, { useEffect, useState } from "react";

import { Button, FormControl, Grid, InputLabel, Select } from "@mui/material";
import { useQuery } from "react-query";
import { MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useNavigate, useLocation } from "react-router-dom";

import ArchivedContractList from "../ArchivedContractList";
import StoreApi from "../../services/Api/Store.Api";
import ContractApi from "../../services/Api/Contract.Api";
import { Filter } from "../ContractList/components";
import CompaniesList from "./components/CompaniesList";

import dynamicStyle from "./style";
import { IsAdmin } from "../../helpers";
import moment from "moment";
import { PaymentFrequency } from "../../utils/MockData/MockData";

const ArchivedStoreList = () => {
  const classes = dynamicStyle();
  const navigate = useNavigate();
  const location = useLocation();

  const [offset, setOffset] = useState(0);
  const [store, setStore] = useState("");
  const [storeId, setStoreId] = useState("");
  const [contractList, setContractLits] = useState([]);
  const [searchedContracts, setSearchedContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openCompanyListDialog, setOpenCompanyListDialog] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [limit, setLimit] = useState(20);

  const [filters, setFilters] = useState({
    startDateStart: null,
    startDateEnd: null,
    expiryDateStart: null,
    expiryDateEnd: null,
  });

  const handleDialog = () => {
    setOpen(!open);
  };

  // Handle Open Company Dialog ..
  const handleCompnayListDialog = () => {
    setOpenCompanyListDialog(!openCompanyDialog);
  };

  // Get Stores api call ..
  const { data } = useQuery("GET_ALL_STORES", () => StoreApi.getStores());

  // Set Store ID and Store Name ...
  useEffect(() => {
    // Set storeID and StoreName when back from contract detail page ..

    if (location?.state?.firstStoreName) {
      setStore(location?.state?.firstStoreName);
      // converting id into number ..
      const firstStoreID = parseInt(location?.state?.firstStoreID, 10);
      setStoreId(firstStoreID);
    } else if (data) {
      setStore(data[0]?.storeName);
      setStoreId(data[0]?.id);
    }
  }, [data]);

  // Get contracts method .
  const getContracts = async () => {
    if (storeId) {
      const response =
        await ContractApi.getArchivedContractsWithFiltersAndPagination({
          storeID: storeId,
          offset: offset,
          startDateStart: filters?.startDateStart,
          startDateEnd: filters?.startDateEnd,
          expiryDateStart: filters?.expiryDateStart,
          expiryDateEnd: filters?.expiryDateEnd,
          limit: limit,
        });
      if (response.message === "No contracts found") {
        setContractLits((prevContracts) => [...prevContracts]);
      }
      if (response.data === undefined) {
        if (offset === 0) {
          setContractLits([]);
        }
      }
      if (response?.data !== undefined) {
        if (offset === 0) {
          setContractLits(response?.data);
        } else {
          setContractLits((prevContracts) => [
            ...prevContracts,
            ...response?.data,
          ]);
        }
      }
    }
  };

  // Query Options
  const queryOptions = { initialData: [], keepPreviousData: true };
  // Get Contracts Based on store ID api call  ...
  const { data: contracts, refetch } = useQuery(
    [
      "GET_CONTRACTS",
      storeId,
      offset,
      filters.startDateStart,
      filters.startDateEnd,
      filters.expiryDateStart,
      filters.expiryDateEnd,
      limit,
    ],
    () => getAllContracts(),
    {
      ...queryOptions,
      enabled: !!storeId,
    }
  );

  const getAllContracts = () => {
    getContracts();
  };

  // Handle Drawer open

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle Click Store Select
  const hadleClick = (id, name) => {
    setStore(name);
    setStoreId(id);
    setOffset(0);
    setContractLits([]);
    setSearchedContracts([]);
    // On Select Store set filters to default values ..
    setFilters({
      startDateStart: null,
      startDateEnd: null,
      expiryDateStart: null,
      expiryDateEnd: null,
    });
  };

  // Handle Filter ...
  const handleFilter = (data) => {
    setFilters({ ...filters, ...data });
  };

  const handleUnarchive = async (id) => {
    let tempData = contractList.filter((contract) => contract.id !== id);
    setContractLits(tempData);
  };

  const rows = contractList.map((contract) => ({
    id: contract.id,
    companyName: contract.companyName,
    contractPerson: contract.contractPerson,
    companyAdvisorEmail: contract.companyAdvisorEmail,
    phoneNo: contract.phoneNo,
    servicesDetails: contract.servicesDetails,
    notes: contract.notes,
    paymentFrequency: contract.paymentFrequency,
    monthlyPayment: contract.monthlyPayment,
    startedDate: moment(contract.startedDate).isValid()
      ? moment(contract.startedDate).format("YYYY-MM-DD")
      : "",
    expiryDate: moment(contract.expiryDate).isValid()
      ? moment(contract.expiryDate).format("YYYY-MM-DD")
      : "",
    status: contract.status,
    storeId: contract.storeId,
  })); // I'm only mapping over relevant data thats needed for the MUI datatable, so if you need something then add it here as well so the row can be passed down -Q

  return (
    <Grid className={classes.main}>
      <Grid style={{ marginTop: "15px", marginLeft: "5px" }}>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="store-select-label">Select Store</InputLabel>
          <Select
            labelId="store-select-label"
            value={store}
            label="Select Store"
          >
            {data?.map((option) => (
              <MenuItem
                onClick={() => hadleClick(option?.id, option?.storeName)}
                key={option?.id}
                value={option?.storeName}
              >
                {option.storeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* Contract List table */}
      <ArchivedContractList
        data={data}
        contractList={contractList}
        loading={loading}
        handleUnarchive={handleUnarchive}
        rows={rows}
        refetchContract={refetch}
        filters={filters}
        setFilters={setFilters}
        storeId={storeId}
        setContractLits={setContractLits}
        setLoading={setLoading}
        setQuery={setQuery}
        setSearch={setSearch}
        query={query}
        open={open}
        setOpen={setOpen}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
      />
    </Grid>
  );
};

export default ArchivedStoreList;
