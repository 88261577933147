export const GET_STORES = "store";
// Contracts apis ..
export const GET_SINGLE_CONTRACT = "contract/single/";
export const CONTRACT_URL = "contract";
export const GET_CONTRACT_FILTERS_PAGINATION = "contract/contracts";
// Auth apis ...
export const SIGN_IN = "auth/login";
export const SING_UP = "auth/register";
// Company apis ...
export const CREATE_COMPNAY = "company/create";
export const GET_COMPANIES = "company/allcompanies";
export const DELETE_COMPANY = "company/";
// Logs apis ....
export const GET_LOGS = "logs/all";
export const GET_CONTRACT_LOGS = "logs/logsByContract";

// Draft Api ..
export const DRAFT_URL = "draft";

// People apis ..
export const PEOPLE_URL = "people";
export const CREATE_PEOPLE = `${PEOPLE_URL}/create`;
export const GET_PEOPLE = `${PEOPLE_URL}/peopleslist`;
export const GET_SINGLE_PEOPLE = `${PEOPLE_URL}`;
export const UPDATE_PEOPLE = `${PEOPLE_URL}/update`;
export const DELETE_PEOPLE = `${PEOPLE_URL}/delete`;
export const GET_ALERT_USERS_FOR_CB = `${PEOPLE_URL}/alertusers/CB`;
