import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import { companyValidationSchema } from "../../../../utils/validations";
import CompnayApi from "../../../../services/Api/Compnay.Api";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import dynamicStyle from "./styles";

const initialValues = {
  companyName: "",
};

const AddCompany = (props) => {
  const { open, setOpen, storeID, refetch } = props;

  const classes = dynamicStyle();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: companyValidationSchema,
    onSubmit: (values) => {
      addCompany(values);
    },
  });

  useEffect(() => {
    if (storeID) {
      formik.setFieldValue("storeID", storeID);
    }
  }, [storeID]);

  // Create Company   ...
  const { mutate: createCompany, isLoading } = useMutation(
    (body) => CompnayApi.createCompany(body),
    {
      onSuccess: (success) => {
        refetch();
        toast.success(success.message);
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const addCompany = (body) => {
    const data = { ...body, storeID };
    createCompany(data);
    handleClose();
    formik.resetForm();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "430px",
          },
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>ADD NEW COMPANY</DialogTitle>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus
            margin="dense"
            name="companyName"
            label="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
            fullWidth
            size="small"
          />
          <DialogActions>
            <Button
              className={classes.deleteButton}
              style={{ backgroundColor: "#ed342a", color: "white" }}
              onClick={handleClose}
              variant="contained"
            >
              <CloseIcon fontSize="small" className={classes.iconsStyle} />{" "}
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              <SaveIcon fontSize="small" className={classes.iconsStyle} /> Save
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default AddCompany;
