import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";

import { CompnayDialog } from "../../../ContractList/components";
import { ConfirmDialog } from "../../../../components";
import CompnayApi from "../../../../services/Api/Compnay.Api";

import dynamicStyle from "./styles";
import { useQuery } from "react-query";

const CompanyList = (props) => {
  const {
    open,
    setOpen,
    storeID,
    openAddCompanyDialog,
    setOpenAddCompanyDialog,
    refetch,
  } = props;

  const [compnayID, setCompanyID] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const classes = dynamicStyle();

  const handleClose = () => {
    setOpen(false);
  };

  // Get all companies ...
  const { data, refetch: refetchCompanies } = useQuery(
    "GET_ALL_COMPANIES",
    () => CompnayApi.getCompanies()
  );

  const handleOpenDialog = () => {
    setOpenAddCompanyDialog(!openAddCompanyDialog);
  };

  const handleOpenConfirm = (id) => {
    setCompanyID(id);
    setConfirmOpen(true);
  };

  const handleDelete = async () => {
    const response = await CompnayApi.deleteCompany(compnayID);
    if (response.success) {
      setConfirmOpen(false);
      toast.success(response.message);
      refetchCompanies();
      refetch();
    }
  };

  return (
    <>
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "330px",
              maxHeight: "400px",
            },
          },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          Manage Companies
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Companies list */}
          <List>
            {data &&
              data?.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText primary={item?.companyName} />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => handleOpenConfirm(item?.id)}>
                      <CloseIcon style={{ color: "red" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <Button onClick={handleOpenDialog}>
            <AddIcon fontSize="small" style={{ marginRight: "10px" }} /> Add
            Company
          </Button>
        </DialogContent>
      </Dialog>
      {/* Add Company Dialog */}
      <CompnayDialog
        open={openAddCompanyDialog}
        setOpen={setOpenAddCompanyDialog}
        storeID={storeID}
        refetch={refetchCompanies}
      />
      {/* Delete Confirmation Dialog  */}
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="delete compnay"
        dialogContext="Are you sure to delete company ?"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default CompanyList;
