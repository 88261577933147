import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles({
  tableContainer: {
    width: "100%",
    marginTop: "40px",
  },
  tableHead: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f1f1f1",
    },
  },
});

export default dynamicStyle;
