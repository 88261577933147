import React, { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useQuery } from "react-query";
import MUIDataTable from "mui-datatables";
import { Delete, Edit } from "@mui/icons-material";

import { FormDialog } from "./components";
import { ConfirmDialog } from "../../components";

import PeopleApi from "../../services/Api/People.Api";
import { toast } from "react-toastify";

const Settings = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [peopleID, setPeopleID] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Get peoples api call ..
  const { data, refetch } = useQuery("GET_PEOPLES", () =>
    PeopleApi.getPeoples()
  );

  // Mui table columns ..
  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "name",
      label: "name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phoneNo",
      label: "Phone Number",
    },

    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          let columnIndex = tableMeta.rowIndex + 1;
          return (
            <>
              <Grid sx={{ display: "flex", flexDirection: "row" }}>
                {/* Delete Icons */}
                <IconButton onClick={() => handleConfirmOpen(id)}>
                  <Delete sx={{ color: "#e53935" }} />
                </IconButton>
                {/* Update draft */}
                <IconButton
                  onClick={() => {
                    handleOpneUpdate(id);
                  }}
                >
                  <Edit color="primary" />
                </IconButton>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: true,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    // responsive,
    selectableRows: "none",
  };

  // Handle delete ..
  const handleDelete = async () => {
    const response = await PeopleApi.deletePeople(peopleID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      refetch();
    }
  };

  // Handle open delete dialog..

  const handleConfirmOpen = (id) => {
    setPeopleID(id);
    setConfirmOpen(true);
  };

  // Handle open dialog  ..
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Handle open update dialog  ..
  const handleOpneUpdate = (id) => {
    setPeopleID(id);
    setOpenDialog(true);
  };
  return (
    <Grid>
      {/* Add Button Grid  */}
      <Grid>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleOpenDialog}
        >
          Add Notification User
        </Button>
      </Grid>
      {/* People table */}
      <Grid sx={{ mt: 2 }}>
        {data && data.length > 0 && (
          <>
            <MUIDataTable
              title={"Notification User"}
              data={data}
              columns={columns}
              options={options}
            />
          </>
        )}
      </Grid>
      {/* Add , Update People Dialog */}
      <Grid>
        <FormDialog
          open={openDialog}
          setOpen={setOpenDialog}
          peopleID={peopleID}
          setPeopleID={setPeopleID}
          refetch={refetch}
        />
      </Grid>
      {/* Delete Confirmation Dialog  */}
      <ConfirmDialog
        title="Delete People ?"
        dialogContext="Are you sure to delete people"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
    </Grid>
  );
};

export default Settings;
