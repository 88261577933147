import React, { useEffect, useRef, useState } from "react";

import { Button, Chip, Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Attachment, Close, Margin } from "@mui/icons-material";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

import CreateContract from "../CreateContract";
import {
  AttachmentList,
  MoreOptions,
  DuplicateContractForm,
} from "./components";

import ContractApi from "../../services/Api/Contract.Api";

import dynamicStyle from "./styles";
import dayjs from "dayjs";
import { IsAdmin } from "../../helpers";

import { DataGrid } from "@mui/x-data-grid";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MUIDataTable from "mui-datatables";

const ArchivedContractList = (props) => {
  const {
    data,
    storeId,
    refetchContract,
    setContractLits,
    contractList,
    loading,
    handleSearch,
    setSearch,
    setQuery,
    open,
    setOpen,
    openAddCompanyDialog,
    setOpenAddCompanyDialog,
    // Filters for csv handling and setting filters ..
    filters,
    setFilters,
    query,
    archived,
    handleUnarchive,
    rows,
    selectedStore,
  } = props;

  const classes = dynamicStyle();
  const location = useLocation();

  // If data has store name  ...
  // const hasStoreName = data.some((item) => item.hasOwnProperty("storeName"));

  // CSV Headers  ...
  // const csvHeaders = [
  //   { label: "ID", key: "id" },
  //   { label: "Company Name", key: "companyName" },
  //   { label: "Company Contact", key: "contractPerson" },
  //   { label: "Contact Email", key: "companyAdvisorEmail" },
  //   { label: "Contact Phone", key: "phoneNo" },
  //   ...(hasStoreName ? [{ label: "Store Name", key: "storeName" }] : []),

  //   { label: "Services Details", key: "servicesDetails" },
  //   { label: "Payment Type", key: "paymentFrequency" },
  //   { label: "Payment Amount", key: "monthlyPayment" },
  //   {
  //     label: "Started Date",
  //     key: "startedDate",
  //   },
  //   {
  //     label: "Expiry Date",
  //     key: "expiryDate",
  //   },
  //   { label: "User", key: "userName" },
  // ];

  // Format date for dates in CSV ..

  //Open dialog Handle  ...
  const [contractId, setContractId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  // Open attachment states ..
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [companyname, setCompanyName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [duplicateOpne, setDuplicateOpen] = useState(null);
  const [cidDuplicate, setCidDuplicate] = useState(null);

  const csvDownloadRef = useRef(null);

  const navigate = useNavigate();

  // For Downloading ...
  const [isDownloading, setIsDownloading] = useState(false);

  // const formatedData = csvData?.map((item) => {
  //   let newItem = {
  //     id: item?.id,
  //     companyName: item?.companyName,
  //     contractPerson: item?.contractPerson,
  //     companyAdvisorEmail: item?.companyAdvisorEmail,
  //     phoneNo: item?.phoneNo,
  //     servicesDetails: item?.servicesDetails,
  //     monthlyPayment: item?.monthlyPayment,
  //     paymentFrequency: item?.paymentFrequency,
  //     startedDate: moment(item?.startedDate).format("DD-MM-YYYY"),
  //     expiryDate: moment(item?.expiryDate).format("DD-MM-YYYY"),
  //     userName: item?.userName,
  //   };
  //   if (hasStoreName) {
  //     newItem.storeName = item.storeName;
  //   }
  //   return newItem;
  // });

  // Selected rows MUI datatable stuff
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelection = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const handleClick = () => {
    setIsDownloading(true);
    // Download CSV Method
    setTimeout(() => {
      setIsDownloading(false);
      downloadCSV();
    }, 3000);
  };

  const editClick = (id) => {
    setContractId(id);
    setOpen(true);
  };

  const handleConfirmOpen = (id) => {
    setContractId(id);
    setConfirmOpen(true);
  };

  const handleDelete = async () => {
    const response = await ContractApi.deleteContract(contractId);
    if (response.success) {
      setContractLits([]);
      toast.success(response.message);
      setConfirmOpen(false);
      refetchContract();
      setContractId(null);
    }
  };

  // Handle open attachment dialog
  const handleOpenAttachmentDialog = (data, compnay) => {
    setCompanyName(compnay);
    setAttachments(data);
    setOpenAttachment(!openAttachment);
  };
  // Handele Set Filters when click on chip ..
  const handleSetFilters = (deletedValue) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      switch (deletedValue) {
        case "StartedDate":
          newFilters.startDateStart = null;
          newFilters.startDateEnd = null;
          break;
        case "ExpiryDate":
          newFilters.expiryDateStart = null;
          newFilters.expiryDateEnd = null;
          break;
        // add cases for other filter types here
        case "StoreName":
          newFilters.storeName = null;
        default:
          break;
      }
      return newFilters;
    });
  };

  // Handle company name click
  const handleCompanyNameClick = (companyName) => {
    navigate("/search", { state: { companyName: companyName } });
    console.log("Company name ==>", companyName);
  };
  const handleOpen = (contactID) => {
    navigate("/contract/" + contactID, { state: { from: location.pathname } });
  };

  // Down Load CSV ...

  const downloadCSV = async () => {
    if (location.pathname === "/search") {
      // Download Golbal Search CSV Data
      const response = await ContractApi.globalSearchContract(query, {
        offset: null,
        storeName: filters.storeName,
        startDateStart: filters.startDateStart,
        startDateEnd: filters.startDateEnd,
        expiryDateStart: filters.expiryDateStart,
        expiryDateEnd: filters.expiryDateEnd,
        limit: null,
      });
      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 1000);
      setCsvData(response.data);
    } else if (query !== null) {
      // Download Dashboard CSV Data
      const response = await ContractApi.searchContractsWithFiltersAndLimit(
        query,
        {
          storeID: storeId,
          offset: null,
          limit: null,
          startDateStart: filters.startDateStart,
          startDateEnd: filters.startDateEnd,
          expiryDateStart: filters.expiryDateStart,
          expiryDateEnd: filters.expiryDateEnd,
        }
      );
      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 1000);
      setCsvData(response.data);
    } else if (query === null) {
      const response = await ContractApi.getContractsWithFiltersAndPagination({
        storeID: storeId,
        offset: null,
        startDateStart: filters?.startDateStart,
        startDateEnd: filters?.startDateEnd,
        expiryDateStart: filters?.expiryDateStart,
        expiryDateEnd: filters?.expiryDateEnd,
        limit: null,
      });

      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 1000);

      setCsvData(response.data);
    }
  };

  //Render Chip  ...
  const RenderChip = ({ label, onDelete }) => {
    return (
      <>
        <Chip
          label={label}
          variant="outlined"
          clickable
          onDelete={onDelete}
          deleteIcon={<Close />}
          style={{ marginRight: "5px", marginBottom: "5px" }}
        />
      </>
    );
  };

  // Handle go to contract-logs page
  const handleNavigate = (singleContractID) => {
    navigate("/contract-logs", { state: { id: singleContractID } });
  };

  const handleDuplicateClick = (id) => {
    setDuplicateOpen(true);
    setCidDuplicate(id);
  };

  const handleArchiveClick = async (id) => {
    handleUnarchive(id);
    await ContractApi.archiveContract(id);
    refetchContract();
  };

  const columns = [
    {
      name: "companyName",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "contractPerson",
      label: "Company Contact",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companyAdvisorEmail",
      label: "Contact Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNo",
      label: "Contact Phone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "servicesDetails",
      label: "Services Details",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "notes",
      label: "Notes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "paymentFrequency",
      label: "Payment Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "monthlyPayment",
      label: "Payment Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "startedDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value ? "" : moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "expiryDate",
      label: "Expiry Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return !value ? "" : moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const contract = contractList[rowIndex];
          return (
            <MoreOptions
              onView={() => handleOpen(contract?.id)}
              onEditClick={() => editClick(contract?.id)}
              onAttachmentClick={() =>
                handleOpenAttachmentDialog(
                  contract?.attachment,
                  contract?.companyName
                )
              }
              handleLogsClick={() => handleNavigate(contract?.id)}
              onArchiveClick={() => handleArchiveClick(contract?.id)}
              archived={contract?.archived}
            />
          );
        },
      },
    },
  ];

  const options = {
    filterType: "textfield",
    selectableRows: "none",
    viewColumns: false,
  };

  const paginationModel = { page: 0, pageSize: 10 };

  return (
    <Grid sx={{}}>
      {/* Spinner on scroll  */}
      {/* <Spinner open={loading} loading={loading} size={60} /> */}
      <Grid
        style={{
          marginTop: "15px",
          marginLeft: "5px",
        }}
      >
        <Paper sx={{}}>
          <MUIDataTable
            title={"Archived Contracts"}
            data={rows}
            columns={columns}
            options={options}
          />
        </Paper>
      </Grid>
      {/* Create or delete idk */}
      <CreateContract
        open={open}
        setOpen={setOpen}
        storeId={storeId}
        setContractId={setContractId}
        cid={contractId}
        refetchContract={refetchContract}
        setContractLits={setContractLits}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
      />
      {/* Attachment list */}
      <AttachmentList
        open={openAttachment}
        setOpen={setOpenAttachment}
        data={attachments}
        company={companyname}
      />
      <DuplicateContractForm
        open={duplicateOpne}
        setOpen={setDuplicateOpen}
        contractId={cidDuplicate}
        setContractId={setCidDuplicate}
        refetchContract={refetchContract}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
        setContractLits={setContractLits}
      />
    </Grid>
  );
};

export default ArchivedContractList;
