import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useQuery } from "react-query";

import UserLogsApi from "../../services/Api/UserLogs.Api";

import dynamicStyle from "./styles";
import { Spinner } from "../../components";

const ContractLogs = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = dynamicStyle();

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = state;

  //   Get logs by contract ID  Use Query....
  const queryOptions = { initialData: [], keepPreviousData: true };
  const { data: contractLogs, refetch } = useQuery(
    ["GET_LOGS_BY_CONTRACT", offset, limit, id],
    () => getContractLogs(),
    {
      enabled: !!id,
      ...queryOptions,
    }
  );

  //   Method GetContract Logs and setting the data based on the offset  ...
  const getContractLogs = async () => {
    const response = await UserLogsApi.getLogsByContract({
      limit: limit,
      offset: offset,
      contractID: id,
    });
    if (response) {
      if (offset === 0) {
        setData(response);
      } else {
        setData((prevData) => [...prevData, ...response]);
      }
    }
  };

  // Handle Scroll End
  const handleScrollEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setOffset((prev) => prev + 20);
      setLoading(false);
    }, 3000);
  };

  //   Handle Scroll  ...
  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        handleScrollEnd();
      }

      //When Scroll reached to top ...
      if (document.documentElement.scrollTop === 0) {
        setOffset(0);
      }
    } catch (error) {
      console.log("scroller catch error ===>", error);
    }
  };

  // Scroll Hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle Back ...
  const handleBack = () => {
    navigate(-1);
  };
  return (
    // Main Grid ..
    <Grid>
      {/* Spinner on scroll End */}
      <Spinner open={loading} loading={loading} size={60} />
      {/* Back Button Grid */}
      <Grid container spacing={1} justifyContent="space-between">
        {/* Go Back Button */}
        <Grid item>
          <Button
            startIcon={<ArrowBackIcon />}
            color="primary"
            size="small"
            variant="contained"
            style={{ margin: "5px" }}
            onClick={handleBack}
          >
            Go Back
          </Button>
        </Grid>
      </Grid>
      {/* Table Grid */}
      <Grid>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>{row?.id}</TableCell>
                    <TableCell>{row?.userName}</TableCell>
                    <TableCell>{row?.details}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ContractLogs;
