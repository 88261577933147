import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useLocation, useNavigate } from "react-router-dom";

import { Filter } from "./components";
import CompnayApi from "../../services/Api/Compnay.Api";
import ContractApi from "../../services/Api/Contract.Api";
import ContractList from "../ContractList";
import moment from "moment";

import dynamicStyle from "./styles";

const GlobalSearch = (props) => {
  const {} = props;

  const navigate = useNavigate();
  const { state } = useLocation();

  const classes = dynamicStyle();

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [contractList, setContractLits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [offset, setOffset] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [limit, setLimit] = useState(20);

  const [filters, setFilters] = useState({
    storeName: null,
    startDateStart: null,
    startDateEnd: null,
    expiryDateStart: null,
    expiryDateEnd: null,
  });

  // Get all companies ...
  const { data: companies, refetch: refetchCompanies } = useQuery(
    "GET_ALL_COMPANIES",
    () => CompnayApi.getCompanies()
  );

  // Query Options
  // Get Contracts Global Search  ...
  const { data: contracts, refetch } = useQuery(
    [
      "GET_CONTRACTS_GLOBAL_SEARCH",
      query,
      offset,
      filters.storeName,
      filters.startDateStart,
      filters.startDateEnd,
      filters.expiryDateStart,
      filters.expiryDateEnd,
      limit,
    ],
    () => handleSearch(),

    {
      keepPreviousData: false,
    }
  );

  const handleSearch = async () => {
    if (query !== "") {
      const response = await ContractApi.globalSearchContract(query, {
        offset: offset,
        storeName: filters.storeName,
        startDateStart: filters.startDateStart,
        startDateEnd: filters.startDateEnd,
        expiryDateStart: filters.expiryDateStart,
        expiryDateEnd: filters.expiryDateEnd,
        limit: limit,
      });
      if (response?.data) {
        if (response?.data) {
          setData((prevContracts) => {
            if (offset === 0) {
              return response?.data;
            } else {
              return [...prevContracts, ...response?.data];
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    if (query === null) {
      setData([]);
      setOffset(0);
    }
  }, [query]);

  // search when company name comes from contract list on click company name
  useEffect(() => {
    if (
      state &&
      state.companyName !== undefined &&
      state.companyName !== null
    ) {
      setQuery(state?.companyName);
      setSearch(true);
      setOffset(0);
      handleSearch();
    } else {
      setQuery(null);
      setSearch(false);
      setData([]);
      setOffset(0);
    }
  }, []);

  // Handle Scroll End
  const handleScrollEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setOffset((prev) => prev + 20);
      setLoading(false);
    }, 3000);
  };

  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        handleScrollEnd();
      }

      //When Scroll reached to top ...
      if (document.documentElement.scrollTop === 0) {
        setOffset(0);
      }
    } catch (error) {
      console.log("scroller catch error ===>", error);
    }
  };

  // Scroll Hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle Open Drawer  ...

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle Filter ...
  const handleFilter = (data) => {
    setFilters({ ...filters, ...data });
  };

  return (
    <Grid>
      <Grid container spacing={1} justifyContent="flex-end">
        {/* Filter Button */}
        <Grid item>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={handleDrawerOpen}
            style={{ margin: "5px" }}
          >
            Show Filters
            <FilterListIcon />
          </Button>
        </Grid>
      </Grid>

      {/* Contract List  */}
      <ContractList
        data={data}
        companies={companies}
        setData={setData}
        open={open}
        setOpen={setOpen}
        setStoreId={storeId}
        setContractLits={setContractLits}
        loading={loading}
        setLoading={setLoading}
        query={query}
        setQuery={setQuery}
        search={search}
        setSearch={setSearch}
        contractList={contractList}
        refetchContract={refetch}
        handleSearch={handleSearch}
        filters={filters}
        setFilters={setFilters}
      />
      {/* Filters  */}
      <Filter
        open={drawerOpen}
        setOpen={setDrawerOpen}
        filters={filters}
        setFilters={setFilters}
        onFilter={handleFilter}
      />
    </Grid>
  );
};

export default GlobalSearch;
