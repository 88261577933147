import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  dialogTitle: {
    backgroundColor: "#1976d2",
    color: "white",
  },
  dialogContent: {
    marginTop: "10px",
  },
  deleteButton: {
    backgroundColor: "#ca0014",
    color: "white",
  },
  iconsStyle: {
    marginRight: "10px",
  },
}));

export default dynamicStyle;
