// Payment frequecny options ..
export const PaymentFrequency = [
  { id: 0, value: "One time fee", label: "One time fee" },
  { id: 1, value: "Weekly", label: "Weekly" },
  { id: 2, value: "Bi-weekly", label: "Bi-weekly" },
  { id: 3, value: "Monthly", label: "Monthly" },
  { id: 4, value: "Quarterly", label: "Quarterly" },
  { id: 5, value: "Yearly", label: "Yearly" },
  { id: 6, value: "Month-to-month", label: "Month-to-month" },
];
