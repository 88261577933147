import * as yup from "yup";

const singUpValidation = {
  userName: "User name is required",
  email: "Email is required",
  password: "Password is required",
  validEmail: "Provide a valid email",
};

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required(singUpValidation.email)
    .email(singUpValidation.validEmail),
  password: yup.string().required(singUpValidation.password),
});

export const signUpValidationSchema = yup.object().shape({
  userName: yup.string().required(singUpValidation.userName),
  email: yup
    .string()
    .required(singUpValidation.email)
    .email(singUpValidation.validEmail),
  password: yup.string().required(singUpValidation.password),
});

const contractValidation = {
  companyName: "Company name required",
  // contractPerson: "Contract person required",
  servicesDetails: "Services details required",
  expiryDate: "Date is not selected",
  monthlyPayment: "Monthly payment required",
  monthlyPaymentTypeError: "Monthly payment must be number",
  paymentFrequency: "Payment frequency required",
  startedDate: "Date is not selected",
  dateTypeError: "Provide valid date",
  storeIDs: "Select a least one store",
  companyAdvisorEmail: "Company advisor email required",
  phoneNo: "Phone number required",
  validEmail: "Provide a valid email",
};

export const contractValidationSchema = yup.object().shape({
  companyName: yup.string().required(contractValidation.companyName),
  // contractPerson: yup.string().required(contractValidation.contractPerson),
  servicesDetails: yup
    .string()
    // .matches(
    //   /^[a-zA-Z][a-zA-Z0-9 ]*[a-zA-Z0-9]$/,
    //   "Service details must start with a letter"
    // )
    .required(contractValidation.servicesDetails),
  expiryDate: yup
    .string()
    .nullable()
    .typeError(contractValidation.dateTypeError)
    .when("paymentFrequency", {
      is: "Month-to-month",
      then: () => yup.string().nullable(),
      otherwise: () =>
        yup.string().nullable().required(contractValidation.expiryDate),
    }),
  monthlyPayment: yup
    .number()
    .typeError(contractValidation.monthlyPaymentTypeError)
    .required(contractValidation.monthlyPayment),
  paymentFrequency: yup.string().required(contractValidation.paymentFrequency),
  startedDate: yup
    .string()
    .nullable()
    .required(contractValidation.startedDate)
    .typeError(contractValidation.dateTypeError),
  companyAdvisorEmail: yup.string().email(contractValidation.validEmail),
  // phoneNo: yup.string().required(contractValidation.phoneNo),
});

const companyValidation = {
  companyName: "Company namre required",
};

export const companyValidationSchema = yup.object().shape({
  companyName: yup.string().required(companyValidation.companyName),
});

// People validations

const peoepleValidation = {
  name: "Name is required",
  email: "Email is required",
  phoneNo: "Phone no is required",

  validEmail: "Provide valid email",
};

export const peopleValidationSchema = yup.object().shape({
  name: yup.string().required(peoepleValidation.name),
  email: yup
    .string()
    .required(peoepleValidation.email)
    .email(peoepleValidation.validEmail),
  phoneNo: yup.string().required(peoepleValidation.phoneNo),
});
