import React, { useEffect, useState } from "react";

import { Button, FormControl, Grid, InputLabel, Select } from "@mui/material";
import { useQuery } from "react-query";
import { MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useNavigate, useLocation } from "react-router-dom";

import ContractList from "../ContractList";
import StoreApi from "../../services/Api/Store.Api";
import ContractApi from "../../services/Api/Contract.Api";
import { Filter } from "../ContractList/components";
import CompaniesList from "./components/CompaniesList";

import dynamicStyle from "./style";
import { IsAdmin } from "../../helpers";

const StoreList = () => {
  const classes = dynamicStyle();
  const navigate = useNavigate();
  const location = useLocation();

  const [offset, setOffset] = useState(0);
  const [store, setStore] = useState("");
  const [storeId, setStoreId] = useState("");
  const [contractList, setContractLits] = useState([]);
  const [searchedContracts, setSearchedContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openCompanyDialog, setOpenCompanyDialog] = useState(false);
  const [openCompanyListDialog, setOpenCompanyListDialog] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [limit, setLimit] = useState(20);

  const [filters, setFilters] = useState({
    startDateStart: null,
    startDateEnd: null,
    expiryDateStart: null,
    expiryDateEnd: null,
  });

  const handleDialog = () => {
    setOpen(!open);
  };

  // Handle Open Company Dialog ..
  const handleCompnayListDialog = () => {
    setOpenCompanyListDialog(!openCompanyDialog);
  };

  // Get Stores api call ..
  const { data } = useQuery("GET_ALL_STORES", () => StoreApi.getStores());

  // Set Store ID and Store Name ...
  useEffect(() => {
    // Set storeID and StoreName when back from contract detail page ..

    if (location?.state?.firstStoreName) {
      setStore(location?.state?.firstStoreName);
      // converting id into number ..
      const firstStoreID = parseInt(location?.state?.firstStoreID, 10);
      setStoreId(firstStoreID);
    } else if (data) {
      setStore(data[0]?.storeName);
      setStoreId(data[0]?.id);
    }
  }, [data]);

  // Get contracts method .
  const getContracts = async () => {
    if (storeId) {
      const response = await ContractApi.getContractsWithFiltersAndPagination({
        storeID: storeId,
        offset: offset,
        startDateStart: filters?.startDateStart,
        startDateEnd: filters?.startDateEnd,
        expiryDateStart: filters?.expiryDateStart,
        expiryDateEnd: filters?.expiryDateEnd,
        limit: limit,
      });
      if (response.data === undefined) {
        if (offset === 0) {
          setContractLits([]);
        }
      }
      if (response?.data !== undefined) {
        if (offset === 0) {
          setContractLits(response?.data);
        } else {
          setContractLits((prevContracts) => [
            ...prevContracts,
            ...response?.data,
          ]);
        }
      }
    }
  };

  const handleSearch = async () => {
    if (query) {
      const response = await ContractApi.searchContractsWithFiltersAndLimit(
        query,
        {
          storeID: storeId,
          offset: offset,
          limit: limit,
          startDateStart: filters.startDateStart,
          startDateEnd: filters.startDateEnd,
          expiryDateStart: filters.expiryDateStart,
          expiryDateEnd: filters.expiryDateEnd,
        }
      );
      // const response = await ContractApi.searchContract(storeId, query, offset);
      if (response?.data) {
        if (offset === 0) {
          setSearchedContracts(response?.data);
        } else {
          setSearchedContracts((prevContracts) => [
            ...prevContracts,
            ...response?.data,
          ]);
        }
      }
    }
  };

  // Query Options
  const queryOptions = { initialData: [], keepPreviousData: true };
  // Get Contracts Based on store ID api call  ...
  const { data: contracts, refetch } = useQuery(
    [
      "GET_CONTRACTS",
      storeId,
      offset,
      filters.startDateStart,
      filters.startDateEnd,
      filters.expiryDateStart,
      filters.expiryDateEnd,
      limit,
    ],
    () => getAllContracts(),
    {
      ...queryOptions,
      enabled: !!storeId,
    }
  );

  const getAllContracts = () => {
    if (search) {
      handleSearch();
    } else {
      getContracts();
    }
  };

  // Handle Drawer open

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle Click Store Select
  const hadleClick = (id, name) => {
    setStore(name);
    setStoreId(id);
    setOffset(0);
    setContractLits([]);
    setSearchedContracts([]);
    // On Select Store set filters to default values ..
    setFilters({
      startDateStart: null,
      startDateEnd: null,
      expiryDateStart: null,
      expiryDateEnd: null,
    });
  };

  // Handle Scroll End
  const handleScrollEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setOffset((prev) => prev + 20);
      setLoading(false);
    }, 3000);
  };

  // Handle Scroll
  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        handleScrollEnd();
      }

      //When Scroll reached to top ...
      if (document.documentElement.scrollTop === 0) {
        setOffset(0);
      }
    } catch (error) {
      console.log("scroller catch error ===>", error);
    }
  };

  // Scroll Hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle Filter ...
  const handleFilter = (data) => {
    setFilters({ ...filters, ...data });
  };

  const handleArchive = async (id) => {
    let tempData = contractList.filter((contract) => contract.id !== id);
    setContractLits(tempData);
  };

  return (
    <Grid className={classes.main}>
      <CompaniesList
        open={openCompanyListDialog}
        setOpen={setOpenCompanyListDialog}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
        storeID={storeId}
      />
      {/* Store Select Menu */}
      <Grid container className={classes.buttonsRow}>
        <Grid item>
          <FormControl>
            <InputLabel>Store</InputLabel>
            <Select
              sx={{ minWidth: "200px" }}
              id="select"
              value={store}
              label="Select store"
            >
              {data &&
                data?.map((option) => (
                  <MenuItem
                    onClick={() => hadleClick(option?.id, option?.storeName)}
                    key={option.id}
                    value={option.storeName}
                  >
                    {option.storeName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item flexDirection="row">
          <Grid item lg={12} sm={12} xs={6}>
            {/* Filters Button */}
            <Button
              startIcon={<FilterListIcon />}
              color="primary"
              size="small"
              variant="outlined"
              onClick={handleDrawerOpen}
              style={{ margin: "5px" }}
            >
              Show Filters
            </Button>

            {IsAdmin() ? (
              <>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => handleCompnayListDialog()}
                  startIcon={<ListAltIcon className={classes.icons} />}
                >
                  Manage companies
                </Button>
                <Button
                  startIcon={<AddIcon className={classes.icons} />}
                  style={{ marginLeft: 8 }}
                  variant="contained"
                  onClick={() => handleDialog()}
                >
                  Add Contract
                </Button>
                {/* Add Company Button */}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* Contract Table  */}
      <Grid className={classes.contractGrid}>
        {/* Filter for Contracts List */}
        <Filter
          open={drawerOpen}
          setOpen={setDrawerOpen}
          filters={filters}
          setFilters={setFilters}
          onFilter={handleFilter}
        />
        {/* Contract List tabel */}
        <ContractList
          data={search ? searchedContracts : contractList}
          storeId={storeId}
          refetchContract={refetch}
          setContractLits={setContractLits}
          loading={loading}
          setLoading={setLoading}
          setQuery={setQuery}
          handleSearch={handleSearch}
          setSearch={setSearch}
          query={query}
          open={open}
          setOpen={setOpen}
          openAddCompanyDialog={openAddCompanyDialog}
          setOpenAddCompanyDialog={setOpenAddCompanyDialog}
          // setFilters and get filters values when filter use
          filters={filters}
          setFilters={setFilters}
          handleArchive={handleArchive}
        />
      </Grid>
    </Grid>
  );
};

export default StoreList;
