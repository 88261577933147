import React, { createContext, useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../config/msalConfig"; // Ensure you have this configuration file setup
import axios from "../utils/Axios";
import LocalStorage from "../managers/LocalStorage";
const authContext = createContext();
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { accounts } = useMsal();

  useEffect(() => {
    const storedUser = LocalStorage.getUser();
    console.log("accounts", accounts);
    if (accounts?.length > 0 && !storedUser) {
      authenticateUser(accounts[0].idToken);
    }
  }, [accounts]);

  useEffect(() => {
    const storedUser = LocalStorage.getUser();
    const storedToken = LocalStorage.getToken();
    console.log("storedUser", storedUser);
    if (storedUser && storedToken) {
      setUser(storedUser);
      setIsLoggedIn(true);
    }
  }, []);

  const signin = async (callback) => {
    await msalInstance
      .loginRedirect({
        scopes: ["User.Read"], // Add other scopes as needed
      })
      .then((response) => {
        console.log("response", response);

        authenticateUser(response.idToken);
        if (callback) callback();
      })
      .catch((error) => {
        console.error(error);
        setIsLoggedIn(false);
      });
  };

  const authenticateUser = async (accessToken, remember) => {
    try {
      console.log("accessToken", accessToken);

      const result = await axios.post(
        "/auth",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("login result");
      console.log(result);

      localStorage.clear();
      sessionStorage.clear();

      sessionStorage.setItem("user", JSON.stringify(result.data.user));
      sessionStorage.setItem("jwt", accessToken);

      LocalStorage.setToken(accessToken);
      LocalStorage.setUser(result.data.user);
      setUser(result.data.user);
      setIsLoggedIn(true);
      // window.location.reload();
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  const signout = (callback) => {
    LocalStorage.removeUser();
    LocalStorage.removeToken();
    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
    setIsLoggedIn(false);
    msalInstance
      .logoutRedirect()
      .then(() => {
        if (callback) callback();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const lock = (callback) => {
    LocalStorage.removeUser();
    LocalStorage.removeToken();
    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
    setIsLoggedIn(false);
  };

  return {
    user,
    signin,
    signout,
    isLoggedIn,
    lock,
  };
}
