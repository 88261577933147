import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArchiveIcon from "@mui/icons-material/Archive";

import dynamicStyle from "./styles";
import { ListAltOutlined, Preview } from "@mui/icons-material";

const HamburgerMenu = ({
  onEditClick,
  onDeleteClick,
  onDuplicateClick,
  onAttachmentClick,
  handleLogsClick,
  onView,
  onArchiveClick,
  archived,
}) => {
  const classes = dynamicStyle();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <IconButton
        size="large"
        edge="start"
        color="primary"
        aria-label="menu"
        aria-controls="hamburger-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="hamburger-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={onView}>
          <Preview className={classes.icons} />
          <Typography className={classes.iconsTitle}>View</Typography>
        </MenuItem>
        <MenuItem onClick={onEditClick}>
          <EditIcon className={classes.icons} />
          <Typography className={classes.iconsTitle}>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={onDeleteClick}>
          <DeleteIcon sx={{ color: "#e53935", marginRight: "5px" }} />
          <Typography className={classes.iconsTitle}>Delete</Typography>
        </MenuItem>
        <MenuItem onClick={onDuplicateClick}>
          <ControlPointDuplicateIcon className={classes.icons} />
          <Typography className={classes.iconsTitle}>Duplicate</Typography>
        </MenuItem>
        <MenuItem onClick={onAttachmentClick}>
          <AttachFileIcon className={classes.icons} />
          <Typography className={classes.iconsTitle}>Attachments</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogsClick}>
          <ListAltOutlined className={classes.icons} />
          <Typography className={classes.iconsTitle}>Logs</Typography>
        </MenuItem>
        <MenuItem onClick={onArchiveClick} style={{ color: "purple" }}>
          <ArchiveIcon className={classes.icons} />
          <Typography className={classes.iconsTitle}>
            {archived ? "Unarchive" : "Archive"}
          </Typography>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default HamburgerMenu;
