import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { isNull } from "lodash";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { IMaskInput } from "react-imask";

import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import { contractValidationSchema } from "../../utils/validations";
import { CreateAlertUser } from "./components";
import ContractApi from "../../services/Api/Contract.Api";
import { valuesToExclude } from "../../constants/AppConstants";

import dynamicStyle from "./styles";
import CompnayApi from "../../services/Api/Compnay.Api";
import StoreApi from "../../services/Api/Store.Api";
import PeopleApi from "../../services/Api/People.Api";
import { PaymentFrequency } from "../../utils/MockData/MockData";

const initialValues = {
  companyName: "",
  contractPerson: "",
  servicesDetails: "",
  expiryDate: null,
  monthlyPayment: "",
  paymentFrequency: "",
  notes: "",
  startedDate: null,
  storeIDs: [],
  companyAdvisorEmail: "",
  phoneNo: "",
  alertUsers: [],
};

const CreateContract = (props) => {
  const {
    open,
    setOpen,
    cid,
    setContractId,
    refetchContract,
    setContractLits,
    openAddCompanyDialog,
    setOpenAddCompanyDialog,
    storeId,
  } = props;

  const classes = dynamicStyle();

  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [removedAttachment, setRemovedAttachments] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [singleContract, setSingleContract] = React.useState([]);
  const [customClick, setCustomClick] = React.useState(false);
  // Reminder days ..
  const [selectedDays, setSelectedDays] = React.useState(["120"]);
  const [customDayValue, setCustomDayValue] = React.useState(null);
  /* For to set alert users after close or api call  whose getting from api
  (when add new alert user then alertUsers list updated with new value) */
  const [alertUserData, setAlertUserData] = React.useState([]);
  // Alert users section
  const [alertUsers, setAlertUsers] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newUser, setNewUser] = React.useState({
    name: "",
    email: "",
    phoneNo: "",
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    const getStores = async () => {
      const response = await StoreApi.getStores();
      setStores(response);
    };

    getStores();
  }, []);

  // Get alert users list
  React.useEffect(() => {
    const getAlertUsers = async () => {
      const response = await PeopleApi.getAlertUsersForCB();
      setAlertUsers(response);
      setAlertUserData(response);
      // Set all alert users selected on create contract page ..
      if (response && cid === null) {
        // formik.setFieldValue("alertUsers", response);
      }
    };

    getAlertUsers();
  }, []);

  // Find object
  const findObject = () => {
    if (storeId) {
      return stores?.find((store) => store?.id === storeId);
    }
  };

  const objFound = findObject(storeId);

  React.useEffect(() => {
    if (objFound) {
      setSelectedOptions([objFound]);
    }
  }, [objFound]);

  let storeIDs = selectedOptions?.map((val) => val?.id);

  React.useEffect(() => {
    const getSingleContract = async () => {
      const response = await ContractApi.getContractByID(cid);
      setSingleContract(response);
    };
    getSingleContract();
  }, [cid]);

  React.useEffect(() => {
    if (singleContract && singleContract.length > 0) {
      const storeIdsStr = singleContract[0]?.store_ids;
      const storeNamesStr = singleContract[0]?.store_names;

      const storeIds = storeIdsStr.split(",").map(Number);
      const storeNames = storeNamesStr.split(",");

      const result = storeIds.map((id, index) => ({
        id,
        storeName: storeNames[index],
      }));

      setSelectedOptions(result);
    }
  }, [singleContract]);

  // Get all companies ...
  const { data: companies, refetch: refetchCompanies } = useQuery(
    "GET_ALL_COMPANIES",
    () => CompnayApi.getCompanies()
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: contractValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  React.useEffect(() => {
    if (formik.values.paymentFrequency === "Month-to-month") {
      formik.setFieldValue("expiryDate", null);
    }
  }, [formik.values.paymentFrequency]);

  // ========================
  // Iterating files on edit
  // ========================
  React.useEffect(() => {
    if (singleContract?.length > 0 && singleContract[0].attachment !== null) {
      setFiles((prev) => [...prev, ...singleContract[0].attachment]);
    } else {
      setFiles([]);
    }
  }, [singleContract]);

  // =========================================
  // set field values if dialog open for edit
  // =========================================
  React.useEffect(() => {
    if (singleContract && singleContract != undefined && !isNull(cid)) {
      const startedDateValue = singleContract[0]?.startedDate;
      const expiryDateValue = singleContract[0]?.expiryDate;

      // Reminder days values  ..
      const reminderDaysVal = singleContract[0]?.reminderDays?.map(
        (val) => val?.day
      );

      // Check if custom day value is exists ..
      const excludedValue = singleContract[0]?.reminderDays?.find(
        (item) => !valuesToExclude.includes(item.day)
      );

      if (excludedValue) {
        setCustomClick(true);
        setCustomDayValue(excludedValue.day);
      } else {
        setCustomClick(false);
        setCustomDayValue("");
      }

      formik.setValues({
        ...formik.values,
        companyName: singleContract[0]?.companyName,
        contractPerson: singleContract[0]?.contractPerson,
        servicesDetails: singleContract[0]?.servicesDetails,
        expiryDate: dayjs(expiryDateValue),
        monthlyPayment: singleContract[0]?.monthlyPayment,
        startedDate: dayjs(startedDateValue),
        // storeID: singleContract[0]?.storeID,
        companyAdvisorEmail: singleContract[0]?.companyAdvisorEmail,
        phoneNo: singleContract[0]?.phoneNo,
        notes: singleContract[0]?.notes,
        paymentFrequency: singleContract[0]?.paymentFrequency,
        alertUsers: singleContract[0]?.alertUsers,
      });
      // Reminder days ..
      reminderDaysVal === undefined || reminderDaysVal === null
        ? setSelectedDays([])
        : setSelectedDays(reminderDaysVal);
    }
  }, [singleContract]);

  React.useEffect(() => {
    if (!singleContract && singleContract === undefined && isNull(cid)) {
      formik.resetForm();
    }
  }, []);

  // ==========================================
  // Formik handle submit on create and update
  // ==========================================
  const handleSubmit = async (body) => {
    try {
      // For update contract  ..
      if (!isNull(cid)) {
        const formData = new FormData();
        // Add the form data to the FormData object
        formData.append("companyName", body.companyName);
        formData.append("contractPerson", body.contractPerson);
        formData.append("servicesDetails", body.servicesDetails);
        formData.append("expiryDate", body.expiryDate);
        console.log("Expiry Date Body", body.expiryDate);
        formData.append("monthlyPayment", body.monthlyPayment);
        formData.append("paymentFrequency", body.paymentFrequency);
        formData.append("notes", body.notes);
        formData.append("startedDate", body.startedDate);
        if (files) {
          for (let i = 0; i < files.length; i++) {
            formData.append("attachments", files[i]);
          }
        }
        formData.append("removedFiles", JSON.stringify(removedAttachment));

        // Store IDs from data ...
        formData.append("storeIDs", JSON.stringify(storeIDs));
        if (storeIDs.length > 1) {
          formData.append("multiple", 1);
        } else {
          formData.append("multiple", 0);
        }

        formData.append("companyAdvisorEmail", body.companyAdvisorEmail);
        formData.append("phoneNo", body.phoneNo);

        // Adding custom days values in selected days array ..
        if (customClick && customDayValue !== null) {
          selectedDays.push(customDayValue);
        }

        formData.append("reminderDays", JSON.stringify(selectedDays));
        formData.append("alertUsers", JSON.stringify(body.alertUsers));

        // Update contract api call  ...
        await ContractApi.updateContract(formData, cid).then((res) => {
          if (res.success) {
            toast.success(res.message);
            // setContractLits([]);
            refetchContract();
            formik.resetForm();
            handleClose();
            setSelectedOptions([objFound]);
            setRemovedAttachments([]);
          }
        });
      } else {
        // create contract api call ...
        const formData = new FormData();
        // Add the form data to the FormData object
        formData.append("companyName", body.companyName);
        formData.append("contractPerson", body.contractPerson);
        formData.append("servicesDetails", body.servicesDetails);
        formData.append("expiryDate", body.expiryDate);
        console.log("Expiry Date Body", body.expiryDate);
        formData.append("monthlyPayment", body.monthlyPayment);
        formData.append("paymentFrequency", body.paymentFrequency);
        formData.append("notes", body.notes);
        formData.append("startedDate", body.startedDate);
        // Add the selected files to the FormData object
        for (let i = 0; i < files.length; i++) {
          formData.append("attachments", files[i]);
        }

        // Store IDs from data ...
        formData.append("storeIDs", JSON.stringify(storeIDs));
        if (storeIDs.length > 1) {
          formData.append("multiple", 1);
        } else {
          formData.append("multiple", 0);
        }
        formData.append("companyAdvisorEmail", body.companyAdvisorEmail);
        formData.append("phoneNo", body.phoneNo);

        // Adding custom days values in selected days array ..
        if (customClick && customDayValue !== null) {
          selectedDays.push(customDayValue);
        }

        formData.append("reminderDays", JSON.stringify(selectedDays));
        formData.append("alertUsers", JSON.stringify(body.alertUsers));

        await ContractApi.createContract(formData)
          .then((res) => {
            setLoading(true);
            if (res.success) {
              toast.success(res.message);
              refetchContract();
              formik.resetForm();
              handleClose();
              setLoading(false);
              setSelectedOptions([objFound]);
              setFiles([]);
            }
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err.message);
            setLoading(false);
          });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Handle save for later submit ..
  const handleSaveForLaterSubmit = async () => {
    formik.setTouched({
      companyName: true,
    });
    // Check if company name is empty ..
    if (!formik.values.companyName) {
      return;
    } else {
      const body = { ...formik.values, draft: 1 };

      // FormData ..
      let formData = new FormData();

      formData.append("companyName", body.companyName);
      formData.append("contractPerson", body.contractPerson);
      formData.append("servicesDetails", body.servicesDetails);
      formData.append("expiryDate", body.expiryDate);
      formData.append("monthlyPayment", body.monthlyPayment);
      formData.append("paymentFrequency", body.paymentFrequency);
      formData.append("notes", body.notes);
      formData.append("startedDate", body.startedDate);
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("attachments", files[i]);
        }
      }
      formData.append("removedFiles", JSON.stringify(removedAttachment));

      // Store IDs from data ...
      formData.append("storeIDs", JSON.stringify(storeIDs));
      if (storeIDs.length > 1) {
        formData.append("multiple", 1);
      } else {
        formData.append("multiple", 0);
      }

      formData.append("companyAdvisorEmail", body.companyAdvisorEmail);
      formData.append("phoneNo", body.phoneNo);
      // Adding custom days values in selected days array ..
      if (customClick && customDayValue !== null) {
        selectedDays.push(customDayValue);
      }

      formData.append("reminderDays", JSON.stringify(selectedDays));
      formData.append("alertUsers", JSON.stringify(body.alertUsers));
      formData.append("draft", 1);

      // Call api for save contract as draft  ..
      await ContractApi.createDraftContract(formData)
        .then((res) => {
          setLoading(true);
          if (res.success) {
            toast.success(res.message);
            // setContractLits([]);
            refetchContract();
            formik.resetForm();
            handleClose();
            navigate("/drafts");
            setLoading(false);
            setSelectedOptions([objFound]);
            setFiles([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    }
  };

  // Handle close ..
  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setCustomClick(false);
    setSelectedDays(["120"]);
    setCustomDayValue("");
    setCustomClick(false);
    setRemovedAttachments([]);

    formik.resetForm();
    if (!isNull(cid)) {
      setContractId(null);
      setSelectedOptions([objFound]);
      setFiles([]);
    }
  };

  const handleOpneCompanyDialog = () => {
    setOpenAddCompanyDialog(!openAddCompanyDialog);
  };

  // ================
  // Open file Input
  // ================
  const handleClick = () => {
    // Show the file input
    const fileInput = document.getElementById("file-input");
    fileInput.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const fileArray = [];

    // Loop through the selected files and add them to the file array
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileArray.push(file);
    }
    setFiles(fileArray);

    // Set the value of the attachments field in the formik state to the selected files
    // formik.setFieldValue("attachments", fileArray);
  };

  // ================================
  // Hanlde Remove Files from files
  // ================================
  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    const removedFile = newFiles.splice(index, 1)[0];
    setFiles(newFiles);
    // keep track removed files ...
    setRemovedAttachments([...removedAttachment, removedFile]);
  };

  // Const Hanlde add more

  const handleAddMore = () => {
    var fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = true;
    fileInput.onchange = function (event) {
      var newFiles = event.target.files;
      for (var i = 0; i < newFiles.length; i++) {
        files.push(newFiles[i]);
      }
      console.log(files);
    };
    fileInput.click();
  };

  const handleOptionChange = (event, value) => {
    setSelectedOptions(value);
  };

  // Reminder days check boxes change ..
  const handleCheckboxDaysChange = (value) => {
    if (selectedDays.includes(value)) {
      setSelectedDays(selectedDays.filter((day) => day !== value));
    } else {
      setSelectedDays([...selectedDays, value]);
    }
  };

  // Handle custom reminder day check box click ..
  const handleCustomClick = () => {
    setCustomClick(!customClick);
  };

  // Remove custom day value when custom day check box is un checked ..
  React.useEffect(() => {
    if (!customClick || !isNull(cid)) {
      setSelectedDays(selectedDays.filter((day) => day !== customDayValue));
    }
  }, [customClick, cid]);

  // Handle custom day text field change ..
  const handleCustomDayInputChange = (e) => {
    setCustomDayValue(e.target.value);
  };

  // Handle open add alert usesrs dialog ..
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  // Handle close add alert users dialog ..
  const handleDialogClose = () => {
    setOpenDialog(false);
    setNewUser({ name: "", email: "", phoneNo: "" });
  };

  // Handle add alert users ..
  const handleAddUser = () => {
    // Adding new alert user in alert users list array ..
    const updatedUsers = [
      ...alertUsers,
      {
        name: newUser.name,
        email: newUser.email,
        phoneNo: newUser.phoneNo,
      },
    ];

    setAlertUsers(updatedUsers);

    // For set formik values to show along with new alert user  ..
    const updatedUsersValues = [
      ...(Array.isArray(formik.values.alertUsers)
        ? formik.values.alertUsers
        : []),
      {
        name: newUser.name,
        email: newUser.email,
        phoneNo: newUser.phoneNo,
      },
    ];
    formik.setFieldValue("alertUsers", updatedUsersValues);
    // setSelectedUsers((prevState) => [...prevState, newUser]);
    setOpenDialog(false);
    setNewUser({ name: "", email: "", phoneNo: "" });
  };
  // Handle input change of add alert users ..
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  // Handle alert users change  ..
  const handleAlertUserChange = (event, values) => {
    formik.setFieldValue("alertUsers", values);
  };

  return (
    <>
      <Grid>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "650px",
              },
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {!isNull(cid) ? "Update Contract " : "Create Contract"}
          </DialogTitle>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {/* select stores auto complete  */}
                  <Autocomplete
                    multiple
                    options={stores || []}
                    getOptionLabel={(option) => option?.storeName}
                    value={selectedOptions}
                    onChange={handleOptionChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="storeIDs"
                        size="small"
                        label="Select Store"
                      />
                    )}
                  />
                  {/* Auto complete empty error  */}
                  {selectedOptions.length === 0 && (
                    <Typography style={{ color: "#d32f2f", fontSize: "13px" }}>
                      Select at least one store
                    </Typography>
                  )}
                </Grid>
                {/* Company Auto Complete  */}
                <Grid item xs={6}>
                  <Autocomplete
                    options={companies || []}
                    getOptionLabel={(option) => option?.companyName}
                    value={
                      companies?.find(
                        (option) =>
                          option.companyName === formik.values.companyName
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      console.log("New Value", newValue);
                      if (newValue) {
                        formik.setValues({
                          ...formik.values,
                          companyName: newValue.companyName,
                        });
                      } else {
                        formik.setValues({
                          ...formik.values,
                          companyName: "",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company Name"
                        variant="outlined"
                        name="companyName"
                        fullWidth
                        size="small"
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        helperText={
                          formik.touched.companyName &&
                          formik.errors.companyName
                        }
                      />
                    )}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper className={classes.paperStyle}>
                          {children}
                          <Button
                            variant="contained"
                            color="inherit"
                            fullWidth
                            onMouseDown={() => {
                              handleOpneCompanyDialog();
                              console.log("Add new");
                            }}
                          >
                            <AddIcon
                              className={classes.iconsStyle}
                              fontSize="small"
                            />
                            Add New
                          </Button>
                        </Paper>
                      );
                    }}
                  />
                </Grid>
                {/* Company advisor textfield */}
                <Grid item xs={6}>
                  <TextField
                    name="contractPerson"
                    label="Company Contact"
                    type="text"
                    value={formik.values.contractPerson}
                    onChange={formik.handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                {/* Company advisor email  */}
                <Grid item xs={6}>
                  <TextField
                    name="companyAdvisorEmail"
                    label="Contact Email"
                    type="mail"
                    value={formik.values.companyAdvisorEmail}
                    onChange={formik.handleChange}
                    fullWidth
                    size="small"
                    error={
                      formik.touched.companyAdvisorEmail &&
                      Boolean(formik.errors.companyAdvisorEmail)
                    }
                    variant="outlined"
                    helperText={
                      formik.touched.companyAdvisorEmail &&
                      formik.errors.companyAdvisorEmail
                    }
                  />
                </Grid>
                {/* Company advisor phone no  */}
                <Grid item xs={6}>
                  <TextField
                    name="phoneNo"
                    label="Contact Phone"
                    type="text"
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      inputComponent: IMaskInput,
                      inputProps: {
                        mask: "+1 (000) 000-0000",
                      },
                    }}
                  />
                </Grid>
                {/* Service details text field */}
                <Grid item xs={6}>
                  <TextField
                    name="servicesDetails"
                    label="Services Details"
                    type="text"
                    value={formik.values.servicesDetails}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.servicesDetails &&
                      Boolean(formik.errors.servicesDetails)
                    }
                    variant="outlined"
                    helperText={
                      formik.touched.servicesDetails &&
                      formik.errors.servicesDetails
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                {/* Started date Date Picker */}
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      label="Started Date"
                      value={formik.values.startedDate}
                      onChange={(date) =>
                        formik.setFieldValue("startedDate", date)
                      }
                      onBlur={() => formik.setFieldTouched("startedDate", true)}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                          error:
                            formik.touched.startedDate &&
                            Boolean(formik.errors.startedDate),
                          helperText:
                            formik.touched.startedDate &&
                            formik.errors.startedDate,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* Expiry Date  */}
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      label="Expiry Date"
                      value={formik.values.expiryDate}
                      onChange={(date) =>
                        formik.setFieldValue("expiryDate", date)
                      }
                      onBlur={() => formik.setFieldTouched("expiryDate", true)}
                      disabled={
                        formik.values.paymentFrequency === "Month-to-month"
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                          error:
                            formik.touched.expiryDate &&
                            Boolean(formik.errors.expiryDate) &&
                            formik.values.paymentFrequency !== "Month-to-month",
                          helperText:
                            formik.touched.expiryDate &&
                            formik.errors.expiryDate &&
                            formik.values.paymentFrequency !== "Month-to-month"
                              ? formik.errors.expiryDate
                              : formik.values.paymentFrequency ===
                                "Month-to-month"
                              ? "Not required for month-to-month contracts"
                              : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* Payment frequency auto complete */}
                <Grid item xs={6}>
                  <Autocomplete
                    options={PaymentFrequency || []}
                    getOptionLabel={(option) => option?.label}
                    value={
                      PaymentFrequency?.find(
                        (option) =>
                          option.value === formik.values.paymentFrequency
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      console.log("New Value", newValue);
                      if (newValue) {
                        formik.setValues({
                          ...formik.values,
                          paymentFrequency: newValue.value,
                        });
                      } else {
                        formik.setValues({
                          ...formik.values,
                          paymentFrequency: "",
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Frequency"
                        variant="outlined"
                        name="paymentFrequency"
                        fullWidth
                        error={
                          formik.touched.paymentFrequency &&
                          Boolean(formik.errors.paymentFrequency)
                        }
                        helperText={
                          formik.touched.paymentFrequency &&
                          formik.errors.paymentFrequency
                        }
                        size="small"
                      />
                    )}
                  />
                </Grid>
                {/* Monthly payment textfield */}
                <Grid item xs={6}>
                  <TextField
                    name="monthlyPayment"
                    label="Payment Amount"
                    type="text"
                    value={formik.values.monthlyPayment}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.monthlyPayment &&
                      Boolean(formik.errors.monthlyPayment)
                    }
                    helperText={
                      formik.touched.monthlyPayment &&
                      formik.errors.monthlyPayment
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                {/* Alert user auto complete .. */}
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    multiple
                    options={alertUsers || []}
                    getOptionLabel={(option) => option?.name}
                    value={formik.values.alertUsers || []}
                    onChange={handleAlertUserChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Alert Users"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="alertUsers"
                      />
                    )}
                    PaperComponent={({ children }) => {
                      return (
                        <Paper className={classes.paperStyle}>
                          {children}
                          <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="inherit"
                            fullWidth
                            onMouseDown={() => {
                              handleDialogOpen();
                              console.log("Add new");
                            }}
                          >
                            <AddIcon
                              className={classes.iconsStyle}
                              fontSize="small"
                            />
                            Add New
                          </Button>
                        </Paper>
                      );
                    }}
                  />
                </Grid>
                {/* Reminder days Check boxes */}
                <Grid item xs={12}>
                  <FormLabel component="legend">Reminder Days</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDays.includes("120")}
                          onChange={() => handleCheckboxDaysChange("120")}
                        />
                      }
                      label="120"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDays.includes("90")}
                          onChange={() => handleCheckboxDaysChange("90")}
                        />
                      }
                      label="90"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDays.includes("60")}
                          onChange={() => handleCheckboxDaysChange("60")}
                        />
                      }
                      label="60"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDays.includes("30")}
                          onChange={() => handleCheckboxDaysChange("30")}
                        />
                      }
                      label="30"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={customClick}
                          onChange={handleCustomClick}
                        />
                      }
                      label="CUSTOM"
                    />
                  </FormGroup>
                  {customClick && (
                    <TextField
                      sx={{ mt: 1 }}
                      label="Days"
                      type="number"
                      fullWidth
                      size="small"
                      value={customDayValue}
                      variant="outlined"
                      onChange={handleCustomDayInputChange}
                    />
                  )}
                </Grid>
                {/* Dialog for push new user */}
                <CreateAlertUser
                  open={openDialog}
                  onClose={handleDialogClose}
                  newUser={newUser}
                  handleInputChange={handleInputChange}
                  handleAddUser={handleAddUser}
                />
                {/* Notes */}
                <Grid item xs={12}>
                  <TextField
                    name="notes"
                    id="outlined-multiline-static"
                    label="Notes"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </Grid>
                {/* File Attachment section  */}
                <Grid item xs={6}>
                  <Button
                    startIcon={<AttachFileIcon />}
                    variant="contained"
                    color="inherit"
                    onClick={handleClick}
                  >
                    Attachment
                  </Button>
                  <input
                    id="file-input"
                    name="attachments"
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                  />
                </Grid>
              </Grid>
              {/* Selected files */}
              <Grid item sx={{ mt: 1 }}>
                {files &&
                  files.map((item, index) => (
                    <>
                      <Grid
                        key={index}
                        container
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography style={{ color: "grey" }}>
                          {item.name}
                        </Typography>
                        <IconButton onClick={() => handleRemoveFile(index)}>
                          <ClearIcon style={{ color: "red" }} />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                {files.length > 0 && (
                  <>
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={() => handleAddMore()}
                    >
                      Add More
                    </Button>
                  </>
                )}
              </Grid>
              <DialogActions>
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {isNull(cid) && (
                  <>
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={handleSaveForLaterSubmit}
                    >
                      Save For Later
                    </Button>
                  </>
                )}

                <Button
                  disabled={loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                  endIcon={
                    loading && <CircularProgress size={22} color="inherit" />
                  }
                >
                  {"Submit"}
                </Button>
              </DialogActions>
            </DialogContent>
          </form>
        </Dialog>
      </Grid>
    </>
  );
};

export default CreateContract;
