import React, { useEffect } from "react";

import { QueryClient, QueryClientProvider } from "react-query";
import Routing from "./routing/Routing";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalInstance } from "./config/msalConfig";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ProvideAuth } from "./hooks/useAuth";

// Component to handle the redirect response
const HandleRedirect = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        console.log("Redirect response:", response);
        // Additional logic to handle response, e.g., setting user state
      })
      .catch((error) => {
        console.error(error);
      });
  }, [instance]);

  return null; // This component does not render anything
};

const App = () => {
  const queryClient = new QueryClient();

  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <ProvideAuth>
          <HandleRedirect />
          <Routing />
        </ProvideAuth>
        <ToastContainer />
      </QueryClientProvider>
    </MsalProvider>
  );
};

export default App;
