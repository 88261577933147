import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterListIcon from "@mui/icons-material/FilterList";
import { SearchBox, Spinner } from "../../components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import UserLogsApi from "../../services/Api/UserLogs.Api";

import dynamicStyle from "./styles";
import { useQuery } from "react-query";

const Logs = () => {
  const classes = dynamicStyle();

  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = async () => {
    const response = await UserLogsApi.getLogs({
      limit: limit,
      offset: offset,
      query: query,
    });
    if (response) {
      if (offset === 0) {
        setData(response);
      } else {
        setData((prevData) => [...prevData, ...response]);
      }
    }
  };

  // call api when query change
  useEffect(() => {
    getLogs();
  }, [query]);

  // Handle Scroll End
  const handleScrollEnd = () => {
    setLoading(true);
    setTimeout(() => {
      setOffset((prev) => prev + 20);
      setLoading(false);
    }, 3000);
  };

  const handleScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        handleScrollEnd();
      }

      //When Scroll reached to top ...
      if (document.documentElement.scrollTop === 0) {
        setOffset(0);
      }
    } catch (error) {
      console.log("scroller catch error ===>", error);
    }
  };

  // Scroll Hook
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  console.log("User Logs  ====>", data);
  //   Handle Search ..
  const handleSearch = async () => {
    console.log("query ===>", query);
  };

  return (
    <Grid>
      {/* Spinner on scroll  */}
      <Spinner open={loading} loading={loading} size={60} />
      {/* Search Box */}
      <SearchBox
        label="Search Logs"
        onSearch={handleSearch}
        setSearch={setSearch}
        setQuery={setQuery}
        setData={data}
      />
      {/* Logs Table */}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data?.map((row, index) => (
                <TableRow key={index} className={classes.tableRow}>
                  <TableCell>{row?.id}</TableCell>
                  <TableCell>{row?.userName}</TableCell>
                  <TableCell>{row?.details}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Logs;
