import React, { useState } from "react";
import { TextField, Grid, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import dynamicStyle from "./styles";

const SearchBox = (props) => {
  const { label, onSearch, setSearch, setQuery, query, setData } = props;

  const classes = dynamicStyle();

  const handleQueryChange = (e) => {
    e.preventDefault();
    const newQ = e.target.value;
    if (newQ.length > 0) {
      setSearch(true);
      setQuery(e.target.value);
    } else {
      setSearch(false);
      setQuery(null);
    }
    onSearch();
  };

  return (
    <Grid className={classes.main} container spacing={2}>
      <Grid className={classes.searchBox} item xs={12} md={6} sm={6}>
        <TextField
          className={classes.searchBox}
          id="standard-search"
          label={label}
          type="search"
          value={query}
          variant="standard"
          autoComplete="off"
          fullWidth
          onChange={handleQueryChange}
          inputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SearchBox;
