import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  paperStyle: {
    textAlign: "center",
  },
  iconsStyle: {
    marginRight: "15px",
  },
}));

export default dynamicStyle;
