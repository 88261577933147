import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dynamicStyle from "./styles";
import StoreApi from "../../../../services/Api/Store.Api";

const Filter = (props) => {
  const { open, setOpen, filters, setFilters, onFilter } = props;
  const classes = dynamicStyle();

  const [storeName, setStoreName] = useState(null);
  const [startDateStart, setStartDateStart] = useState(null);
  const [startDateEnd, setStartDateEnd] = useState(null);
  const [expiryDateStart, setExpiryDateStart] = useState(null);
  const [expiryDateEnd, setExpiryDateEnd] = useState(null);
  const [sd, setSD] = useState([]);

  useEffect(() => {
    const getStore = async () => {
      const storesValues = await StoreApi.getStores();
      setSD(storesValues);
    };
    getStore();
  }, []);

  const onStartDateStart = (value) => {
    setStartDateStart(value);
  };

  const onStartDateEnd = (value) => {
    setStartDateEnd(value);
  };

  const onExpiryDateStart = (value) => {
    setExpiryDateStart(value);
  };
  const onExpiryDateEnd = (value) => {
    setExpiryDateEnd(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter &&
      onFilter({
        storeName,
        startDateStart,
        startDateEnd,
        expiryDateStart,
        expiryDateEnd,
      });

    setOpen(false);
    // onFilter && onFilter();
  };
  const handleClearFilters = (event) => {
    event.preventDefault();
    setStoreName(null);
    setStartDateStart(null);
    setStartDateEnd(null);
    setExpiryDateStart(null);
    setExpiryDateEnd(null);
    onFilter &&
      onFilter({
        storeName: null,
        startDateStart: null,
        startDateEnd: null,
        expiryDateStart: null,
        expiryDateEnd: null,
      });
    setOpen(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
    >
      <Grid>
        <Card>
          <CardHeader
            avatar={
              <CloseIcon
                onClick={handleDrawerClose}
                style={{ cursor: "pointer" }}
              />
            }
            title="Close"
          />
        </Card>
      </Grid>

      <div role="presentation">
        {/* Store Name */}
        <Typography className={classes.filterTitle} variant="h6">
          Store Name
        </Typography>
        {/* Form  */}
        <Grid container className={classes.textFieldGrid} spacing={2}>
          <Grid item xs={6} sm={12} lg={12}>
            {/* Other auto complete */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={sd || []}
              value={
                sd?.find((option) => option.storeName === storeName) || null
              }
              getOptionLabel={(option) => option.storeName}
              onChange={(even, value) => {
                if (value) {
                  setStoreName(value.storeName);
                } else {
                  setStoreName(null);
                }
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Store name"
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        {/* Started Date  */}
        <Typography className={classes.filterTitle} variant="h6">
          Started Date
        </Typography>
        {/* Form  */}
        <Grid container className={classes.textFieldGrid} spacing={2}>
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                variant="outlined"
                name="start"
                sx={{ width: "100%" }}
                label="Start"
                value={startDateStart}
                onChange={(val) => onStartDateStart(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",

                    // value={startDateStart}
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                variant="outlined"
                sx={{ width: "100%" }}
                name="end"
                label="End"
                value={startDateEnd}
                onChange={(val) => onStartDateEnd(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Typography className={classes.filterTitle} variant="h6">
          Expiry Date
        </Typography>
        {/* Expirt Date */}
        <Grid container className={classes.textFieldGrid} spacing={2}>
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                variant="outlined"
                sx={{ width: "100%" }}
                name="start"
                label="Start"
                value={expiryDateStart}
                onChange={(val) => onExpiryDateStart(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                variant="outlined"
                sx={{ width: "100%" }}
                name="end"
                label="End"
                value={expiryDateEnd}
                onChange={(val) => onExpiryDateEnd(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
            {/* Actions Section */}

            <Grid container>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                className={classes.buttonsGrid}
              >
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  onClick={handleClearFilters}
                >
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                className={classes.buttonsGrid}
              >
                <Button
                  style={{ width: "100%" }}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Apply Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export default Filter;
