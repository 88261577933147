import React, { useEffect, useRef, useState } from "react";

import { Button, Chip, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Attachment,
  Close,
  OpenInBrowser,
  OpenInFull,
  Preview,
  ViewAgenda,
  ViewComfy,
} from "@mui/icons-material";
import { CSVLink, CSVDownload } from "react-csv";

import CreateContract from "../CreateContract";
import { ConfirmDialog, Spinner, SearchBox } from "../../components";
import {
  AttachmentList,
  MoreOptions,
  DuplicateContractForm,
} from "./components";

import ContractApi from "../../services/Api/Contract.Api";

import dynamicStyle from "./styles";
import dayjs from "dayjs";
import { IsAdmin } from "../../helpers";

const ContractList = (props) => {
  const {
    data,
    storeId,
    refetchContract,
    setContractLits,
    loading,
    handleSearch,
    setSearch,
    setQuery,
    open,
    setOpen,
    openAddCompanyDialog,
    setOpenAddCompanyDialog,
    // Filters for csv handling and setting filters ..
    filters,
    setFilters,
    query,
    archived,
    handleArchive,
  } = props;

  const classes = dynamicStyle();
  const location = useLocation();

  // If data has store name  ...
  const hasStoreName = data.some((item) => item.hasOwnProperty("storeName"));

  // CSV Headers  ...
  const csvHeaders = [
    { label: "ID", key: "id" },
    { label: "Company Name", key: "companyName" },
    { label: "Company Contact", key: "contractPerson" },
    { label: "Contact Email", key: "companyAdvisorEmail" },
    { label: "Contact Phone", key: "phoneNo" },
    ...(hasStoreName ? [{ label: "Store Name", key: "storeName" }] : []),

    { label: "Services Details", key: "servicesDetails" },
    { label: "Payment Type", key: "paymentFrequency" },
    { label: "Payment Amount", key: "monthlyPayment" },
    {
      label: "Started Date",
      key: "startedDate",
    },
    {
      label: "Expiry Date",
      key: "expiryDate",
    },
    { label: "User", key: "userName" },
  ];

  // Format date for dates in CSV ..

  //Open dialog Handle  ...
  const [contractId, setContractId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  // Open attachment states ..
  const [openAttachment, setOpenAttachment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [companyname, setCompanyName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [duplicateOpne, setDuplicateOpen] = useState(null);
  const [cidDuplicate, setCidDuplicate] = useState(null);

  const csvDownloadRef = useRef(null);

  const navigate = useNavigate();
  // For Sorting  ..
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  // For Downloading ...
  const [isDownloading, setIsDownloading] = useState(false);

  const formatedData = csvData?.map((item) => {
    let newItem = {
      id: item?.id,
      companyName: item?.companyName,
      contractPerson: item?.contractPerson,
      companyAdvisorEmail: item?.companyAdvisorEmail,
      phoneNo: item?.phoneNo,
      servicesDetails: item?.servicesDetails,
      monthlyPayment: item?.monthlyPayment,
      paymentFrequency: item?.paymentFrequency,
      startedDate: moment(item?.startedDate).format("DD-MM-YYYY"),
      expiryDate: moment(item?.expiryDate).format("DD-MM-YYYY"),
      userName: item?.userName,
    };
    if (hasStoreName) {
      newItem.storeName = item.storeName;
    }
    return newItem;
  });

  const handleClick = () => {
    setIsDownloading(true);
    // Download CSV Method
    setTimeout(() => {
      setIsDownloading(false);
      downloadCSV();
    }, 3000);
  };

  // Handle Sort ..
  const handleSort = (property) => () => {
    setOrderBy(property);
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  let sortedContracts = data?.sort((a, b) => {
    const sortOrder = order === "asc" ? 1 : -1;
    if (orderBy === "startedDate") {
      return sortOrder * (new Date(a.startedDate) - new Date(b.startedDate));
    }

    if (orderBy === "expiryDate") {
      return sortOrder * (new Date(a.expiryDate) - new Date(b.expiryDate));
    }
    return null;
  });

  const editClick = (id) => {
    setContractId(id);
    setOpen(true);
  };

  const handleConfirmOpen = (id) => {
    setContractId(id);
    setConfirmOpen(true);
  };

  const handleDelete = async () => {
    const response = await ContractApi.deleteContract(contractId);
    if (response.success) {
      setContractLits([]);
      toast.success(response.message);
      setConfirmOpen(false);
      refetchContract();
      setContractId(null);
    }
  };

  // Handle open attachment dialog
  const handleOpenAttachmentDialog = (data, compnay) => {
    setCompanyName(compnay);
    setAttachments(data);
    setOpenAttachment(!openAttachment);
  };
  // Handele Set Filters when click on chip ..
  const handleSetFilters = (deletedValue) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      switch (deletedValue) {
        case "StartedDate":
          newFilters.startDateStart = null;
          newFilters.startDateEnd = null;
          break;
        case "ExpiryDate":
          newFilters.expiryDateStart = null;
          newFilters.expiryDateEnd = null;
          break;
        // add cases for other filter types here
        case "StoreName":
          newFilters.storeName = null;
        default:
          break;
      }
      return newFilters;
    });
  };

  // Handle company name click
  const handleCompanyNameClick = (companyName) => {
    navigate("/search", { state: { companyName: companyName } });
    console.log("Company name ==>", companyName);
  };
  const handleOpen = (contactID) => {
    navigate("/contract/" + contactID);
  };

  // Down Load CSV ...

  const downloadCSV = async () => {
    if (location.pathname === "/search") {
      // Download Golbal Search CSV Data
      const response = await ContractApi.globalSearchContract(query, {
        offset: null,
        storeName: filters.storeName,
        startDateStart: filters.startDateStart,
        startDateEnd: filters.startDateEnd,
        expiryDateStart: filters.expiryDateStart,
        expiryDateEnd: filters.expiryDateEnd,
        limit: null,
      });
      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 1000);
      setCsvData(response.data);
    } else if (query !== null) {
      // Download Dashboard CSV Data
      const response = await ContractApi.searchContractsWithFiltersAndLimit(
        query,
        {
          storeID: storeId,
          offset: null,
          limit: null,
          startDateStart: filters.startDateStart,
          startDateEnd: filters.startDateEnd,
          expiryDateStart: filters.expiryDateStart,
          expiryDateEnd: filters.expiryDateEnd,
        }
      );
      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 1000);
      setCsvData(response.data);
    } else if (query === null) {
      const response = await ContractApi.getContractsWithFiltersAndPagination({
        storeID: storeId,
        offset: null,
        startDateStart: filters?.startDateStart,
        startDateEnd: filters?.startDateEnd,
        expiryDateStart: filters?.expiryDateStart,
        expiryDateEnd: filters?.expiryDateEnd,
        limit: null,
      });

      setTimeout(() => {
        csvDownloadRef.current.link.click();
      }, 1000);

      setCsvData(response.data);
    }
  };

  //Render Chip  ...
  const RenderChip = ({ label, onDelete }) => {
    return (
      <>
        <Chip
          label={label}
          variant="outlined"
          clickable
          onDelete={onDelete}
          deleteIcon={<Close />}
          style={{ marginRight: "5px", marginBottom: "5px" }}
        />
      </>
    );
  };

  // Handle go to contract-logs page
  const handleNavigate = (singleContractID) => {
    navigate("contract-logs", { state: { id: singleContractID } });
  };

  const handleDuplicateClick = (id) => {
    setDuplicateOpen(true);
    setCidDuplicate(id);
  };

  const handleArchiveClick = async (id) => {
    handleArchive(id);
    // Note this is to ARCHIVE the contract to avoid future confusion
    await ContractApi.archiveContract(id);
    refetchContract();
  };

  // console.log(filters.startDateStart);

  return (
    <Grid>
      {/* Spinner on scroll  */}
      <Spinner open={loading} loading={loading} size={60} />
      {/* Filters Values Grid */}
      <Grid style={{ marginTop: "15px", marginLeft: "5px" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {/* Store Name Chip When Global Search */}
          {location.pathname === "/search" && filters.storeName !== null && (
            <>
              {/* Store Name */}
              <RenderChip
                label={`Store Name: ${filters.storeName}`}
                onDelete={() => {
                  handleSetFilters("StoreName");
                }}
              />
            </>
          )}
          {/* Start Date Chip Values */}
          {filters.startDateStart !== null && filters.startDateEnd !== null && (
            <>
              {/* Started Date Start Chip */}
              <RenderChip
                label={`Start Date Start: ${dayjs(
                  filters.startDateStart
                ).format("YYYY-MM-DD")}`}
                onDelete={() => {
                  handleSetFilters("StartedDate");
                }}
              />
              {/* Started Date End Chip  */}
              <RenderChip
                label={`Start Date End: ${dayjs(filters.startDateEnd).format(
                  "YYYY-MM-DD"
                )}`}
                onDelete={() => {
                  handleSetFilters("StartedDate");
                }}
              />
            </>
          )}
          {/* Expiry Date  Chip Values */}
          {filters.expiryDateStart !== null &&
            filters.expiryDateEnd !== null && (
              <>
                {/* Expiry Date Start Chip */}
                <RenderChip
                  label={`Expiry Date Start: ${dayjs(
                    filters.expiryDateStart
                  ).format("DD-MM-YYYY")}`}
                  onDelete={() => {
                    handleSetFilters("ExpiryDate");
                  }}
                />
                {/* Expiry Date End Chip  */}
                <RenderChip
                  label={`Expiry Date End: ${dayjs(
                    filters.expiryDateEnd
                  ).format("DD-MM-YYYY")}`}
                  onDelete={() => {
                    handleSetFilters("ExpiryDate");
                  }}
                />
              </>
            )}
        </div>
      </Grid>
      {/* Search Box */}
      <Grid className={classes.searchBox}>
        <SearchBox
          label={"Search Contract"}
          onSearch={handleSearch}
          setSearch={setSearch}
          setQuery={setQuery}
          setData={data}
          query={query}
        />
      </Grid>
      <Grid className={classes.tableGrid}>
        {/* CSV Download Button  */}
        {(sortedContracts &&
          sortedContracts?.length > 0 &&
          filters.startDateStart !== null &&
          filters.startDateEnd !== null) ||
        (sortedContracts &&
          sortedContracts?.length > 0 &&
          filters.expiryDateStart !== null &&
          filters.expiryDateEnd !== null) ? (
          <>
            <CSVLink
              className={classes.linkStyle}
              data={formatedData}
              headers={csvHeaders}
              filename="contract_records.csv"
              ref={csvDownloadRef}
            />

            <Button
              startIcon={<DownloadIcon />}
              style={{ marginBottom: "5px" }}
              variant="contained"
              color="primary"
              disabled={isDownloading}
              onClick={handleClick}
              endIcon={
                isDownloading && <CircularProgress size={18} color="inherit" />
              }
            >
              Download csv
            </Button>
          </>
        ) : null}
        {/* Sorted Table  */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell align="center">Company Contact</TableCell>
                <TableCell align="center">Contact Email</TableCell>
                <TableCell align="center">Contact Phone</TableCell>

                {/* If Search Dialog open */}
                {location.pathname === "/search" && (
                  <TableCell align="center">Store Name</TableCell>
                )}
                <TableCell align="center">Services Details</TableCell>
                <TableCell align="center">Notes</TableCell>
                <TableCell align="center">Payment Type</TableCell>
                <TableCell align="center">Payment Amount</TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "startedDate"}
                    direction={orderBy === "startedDate" ? order : "asc"}
                    onClick={handleSort("startedDate")}
                  >
                    Started Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === "expiryDate"}
                    direction={orderBy === "expiryDate" ? order : "asc"}
                    onClick={handleSort("expiryDate")}
                  >
                    Expiry Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedContracts &&
                sortedContracts?.map((contract, index) => {
                  let isExpired = moment().isAfter(moment(contract.expiryDate));
                  return (
                    <TableRow
                      key={index}
                      style={
                        isExpired
                          ? { background: "rgba(255, 126, 126, 0.4)" }
                          : {}
                      }
                    >
                      <TableCell className={classes.tableCell}>
                        <Button
                          onClick={() => {
                            if (location.pathname === "/") {
                              handleCompanyNameClick(contract?.companyName);
                            }
                          }}
                        >
                          {contract?.companyName}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {contract?.contractPerson}
                      </TableCell>
                      <TableCell component="th">
                        {contract?.companyAdvisorEmail}
                      </TableCell>
                      <TableCell component="th">{contract?.phoneNo}</TableCell>
                      {location.pathname === "/search" && (
                        <TableCell align="center">
                          {contract?.storeName}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {contract?.servicesDetails}
                      </TableCell>
                      <TableCell align="center">
                        {contract?.notes?.length > 16
                          ? `${contract?.notes.substring(0, 15)}...`
                          : contract?.notes}
                      </TableCell>
                      <TableCell align="center">
                        {contract?.paymentFrequency}
                      </TableCell>
                      <TableCell align="center">
                        ${contract?.monthlyPayment}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                        {moment(contract?.startedDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          whiteSpace: "nowrap",
                          color: isExpired ? "#ED342A" : "",
                        }}
                      >
                        {contract?.expiryDate
                          ? moment(contract?.expiryDate).format("YYYY-MM-DD")
                          : ""}
                      </TableCell>
                      {/* Action Section */}
                      <TableCell>
                        {IsAdmin() ? (
                          <MoreOptions
                            onView={() => handleOpen(contract?.id)}
                            onEditClick={() => editClick(contract?.id)}
                            onDeleteClick={() =>
                              handleConfirmOpen(contract?.id)
                            }
                            onDuplicateClick={() =>
                              handleDuplicateClick(contract?.id)
                            }
                            onAttachmentClick={() =>
                              handleOpenAttachmentDialog(
                                contract?.attachment,
                                contract?.companyName
                              )
                            }
                            handleLogsClick={() => handleNavigate(contract?.id)}
                            onArchiveClick={() =>
                              handleArchiveClick(contract?.id)
                            }
                            archived={contract?.archived}
                          />
                        ) : (
                          <Grid container>
                            {contract?.attachment?.length > 0 ? (
                              <Button
                                startIcon={<Attachment />}
                                onClick={() =>
                                  handleOpenAttachmentDialog(
                                    contract?.attachment,
                                    contract?.companyName
                                  )
                                }
                              >
                                Attachments
                              </Button>
                            ) : (
                              <></>
                            )}
                            <Button
                              variant="contained"
                              onClick={() => handleOpen(contract?.id)}
                            >
                              View
                            </Button>
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* Search Box */}
      {/* Create Contract Dialog */}
      <CreateContract
        open={open}
        setOpen={setOpen}
        storeId={storeId}
        setContractId={setContractId}
        cid={contractId}
        refetchContract={refetchContract}
        setContractLits={setContractLits}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
      />
      {/* Delete Confirmation Dialog  */}
      <ConfirmDialog
        title="Delete contract ?"
        dialogContext="Are you sure to delete contract"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
      />
      {/* Attachment list */}
      <AttachmentList
        open={openAttachment}
        setOpen={setOpenAttachment}
        data={attachments}
        company={companyname}
      />
      {/* Duplicate Contract Form */}
      <DuplicateContractForm
        open={duplicateOpne}
        setOpen={setDuplicateOpen}
        contractId={cidDuplicate}
        setContractId={setCidDuplicate}
        refetchContract={refetchContract}
        openAddCompanyDialog={openAddCompanyDialog}
        setOpenAddCompanyDialog={setOpenAddCompanyDialog}
        setContractLits={setContractLits}
      />
    </Grid>
  );
};

export default ContractList;
