import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  dialogTitle: {
    backgroundColor: "#1976d2",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContent: {
    marginTop: "10px",
  },
}));

export default dynamicStyle;
