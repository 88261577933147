import { api } from "../../utils";
import {
  CREATE_PEOPLE,
  DELETE_PEOPLE,
  GET_ALERT_USERS_FOR_CB,
  GET_PEOPLE,
  GET_SINGLE_PEOPLE,
  UPDATE_PEOPLE,
} from "../ApiConstants";

class PeopleApi {
  static sharedInstance = new PeopleApi();

  constructor() {
    if (PeopleApi.sharedInstance !== null) {
      return PeopleApi.sharedInstance;
    }
  }

  //   Create people ..
  async createPeople(body) {
    try {
      const response = await api.post(`${CREATE_PEOPLE}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  //   Get peoples  ..
  async getPeoples() {
    try {
      const response = await api.get(`${GET_PEOPLE}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //   Get single people ..
  async getSinglePeople(id) {
    try {
      const response = await api.get(`${GET_SINGLE_PEOPLE}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //   Update People ..
  async updatePeople(id, body) {
    try {
      const response = await api.put(`${UPDATE_PEOPLE}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  //   Delete People  ..
  async deletePeople(id) {
    try {
      const response = await api.delete(`${DELETE_PEOPLE}/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Get allert users for check boxes ..
  async getAlertUsersForCB() {
    try {
      const response = await api.get(`${GET_ALERT_USERS_FOR_CB}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }
}

export default PeopleApi.sharedInstance;
