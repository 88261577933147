import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  main: {
    flex: 1,
  },
  tableGrid: {
    marginTop: "20px",
  },
  tableCell: {
    padding: 4,
  },
  iconsHover: {
    "&:hover": {
      cursor: "pointer",
    },
    searchBox: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  linkStyle: {
    textDecoration: "none",
  },
}));

export default dynamicStyle;
