import { api } from "../../utils";
import { GET_LOGS, GET_CONTRACT_LOGS } from "../ApiConstants";

class LogsApi {
  static sharedInstance = new LogsApi();
  constructor() {
    if (LogsApi.sharedInstance != null) {
      return LogsApi.sharedInstance;
    }
  }

  // Get Logs   ...
  async getLogs(body) {
    try {
      const response = await api.post(GET_LOGS, body);
      const { data, message, success } = response.data;
      if (success) {
        return data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
  // Get Logs By Contract ...
  async getLogsByContract(body) {
    try {
      const response = await api.post(GET_CONTRACT_LOGS, body);
      const { data, message, success } = response.data;
      if (success) {
        return data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
}

export default LogsApi.sharedInstance;
