import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import ContractApi from "../../services/Api/Contract.Api";

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import StoreApi from "../../services/Api/Store.Api";
import CompnayApi from "../../services/Api/Compnay.Api";
import { useQuery } from "react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { PaymentFrequency } from "../../utils/MockData/MockData";
import PeopleApi from "../../services/Api/People.Api";
import { valuesToExclude } from "../../constants/AppConstants";
import { Download } from "@mui/icons-material";
import FileDownload from "js-file-download";
import { ArrowBack } from "@mui/icons-material";

const ContractDetail = (props) => {
  const { setOpen } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [contractData, setContractData] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [selectedAlertUsers, setSelectedAlertUsers] = useState([]);
  const [alertUsers, setAlertUsers] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [customClick, setCustomClick] = useState(false);
  const [customDayValue, setCustomDayValue] = useState("");
  const [files, setFiles] = useState([]);

  // Get all companies ...
  const { data: companies } = useQuery("GET_ALL_COMPANIES", () =>
    CompnayApi.getCompanies()
  );

  // Set side bar open false on component mount

  useEffect(() => {
    setOpen(false);
  }, []);
  // Get contract detail ..
  useEffect(() => {
    const getContract = async () => {
      const response = await ContractApi.getContractByID(id);
      setContractData(response);
    };
    getContract();
  }, [id]);

  // Get stores ..
  useEffect(() => {
    const getStores = async () => {
      const response = await StoreApi.getStores();
      setStores(response);
    };

    getStores();
  }, []);

  // Get alert users
  useEffect(() => {
    const getAlertUsers = async () => {
      const response = await PeopleApi.getAlertUsersForCB();
      setAlertUsers(response);
    };

    getAlertUsers();
  }, []);

  // Set  Selected Stores , alert uses and reminder days ..
  useEffect(() => {
    if (contractData && contractData.length > 0) {
      const storeIdsStr = contractData[0]?.store_ids;
      const storeNamesStr = contractData[0]?.store_names;

      const storeIds = storeIdsStr.split(",").map(Number);
      const storeNames = storeNamesStr.split(",");

      const result = storeIds.map((id, index) => ({
        id,
        storeName: storeNames[index],
      }));

      setSelectedStores(result);

      // Set selected days and
      // Reminder days values  ..
      const reminderDaysVal = contractData[0]?.reminderDays?.map(
        (val) => val?.day
      );

      setSelectedAlertUsers(contractData[0]?.alertUsers);
      // Check if custom day value is exists ..
      const excludedValue = contractData[0]?.reminderDays?.find(
        (item) => !valuesToExclude.includes(item.day)
      );

      if (excludedValue) {
        setCustomClick(true);
        setCustomDayValue(excludedValue.day);
      } else {
        setCustomClick(false);
        setCustomDayValue("");
      }

      reminderDaysVal === undefined || reminderDaysVal === null
        ? setSelectedDays(["120"])
        : setSelectedDays(reminderDaysVal);
    }
  }, [contractData]);

  // Set files  ..
  React.useEffect(() => {
    if (contractData?.length > 0 && contractData[0].attachment !== null) {
      setFiles((prev) => [...prev, ...contractData[0].attachment]);
    } else {
      setFiles([]);
    }
  }, [contractData]);

  // Download attachments  ..
  const handleDownload = async (companyName, email, filename) => {
    const response = await ContractApi.downloadAttachment(
      companyName,
      email,
      filename
    );
    FileDownload(response.data, filename);
  };

  const storeID = contractData[0]?.store_ids;
  const storeName = contractData[0]?.store_names;
  let firstStoreID;
  let firstStoreName;

  if (storeID && storeName) {
    const storeIDArray = storeID.split(",");
    const storeNameArray = storeName.split(",");
    firstStoreID = storeIDArray[0];
    firstStoreName = storeNameArray[0];
  }

  const handleBack = () => {
    setOpen(true);
    const previousPath = location.state?.from || "/";
    navigate(previousPath, { state: { firstStoreID, firstStoreName } });
  };

  return (
    <Grid sx={{ mt: 2 }}>
      {contractData && contractData.length > 0 ? (
        <>
          <Grid>
            <Button
              startIcon={<ArrowBack />}
              variant="contained"
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
          <Grid container spacing={3.5}>
            {/* Stores Autocomplete  */}
            <Grid item md={6} xs={12} sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                options={stores || []}
                getOptionLabel={(option) => option?.storeName}
                value={selectedStores}
                readOnly
                size="small"
                fullWidth
                variant="outlined"
                renderInput={(params) => (
                  <TextField {...params} label="Stores" readOnly />
                )}
              />
            </Grid>
            {/* Company Autocomplete */}
            <Grid item md={6} xs={12} sx={{ mt: 2 }}>
              <Autocomplete
                options={companies || []}
                value={contractData[0]?.companyName}
                readOnly
                variant="outlined"
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Company Name" readOnly />
                )}
              />
            </Grid>
            {/* Company Contact */}
            <Grid item md={6} xs={12}>
              <TextField
                value={contractData[0]?.contractPerson}
                readOnly
                label="Company Contact"
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
            {/* Company Advisor */}
            <Grid item md={6} xs={12}>
              <TextField
                value={contractData[0]?.companyAdvisorEmail}
                readOnly
                label="Contact Email"
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
            {/* Contact Phone */}
            <Grid item md={6} xs={12}>
              <TextField
                value={contractData[0]?.phoneNo}
                readOnly
                label="Contact Phone"
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
            {/* Services Details */}
            <Grid item md={6} xs={12}>
              <TextField
                value={contractData[0]?.servicesDetails}
                readOnly
                label="Services Details"
                fullWidth
                size="small"
                variant="outlined"
              />
            </Grid>
            {/* Start Date */}
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  variant="outlined"
                  sx={{ width: "100%" }}
                  label="Started Date"
                  format="YYYY-MM-DD"
                  readOnly
                  value={dayjs(contractData[0]?.startedDate)}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* Expiry Date */}
            <Grid item md={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  variant="outlined"
                  sx={{ width: "100%" }}
                  label="Expiry Date"
                  format="YYYY-MM-DD"
                  readOnly
                  value={
                    contractData[0]?.paymentFrequency === "Month-to-month"
                      ? null
                      : dayjs(contractData[0]?.expiryDate)
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      disabled:
                        contractData[0]?.paymentFrequency === "Month-to-month",
                      helperText:
                        contractData[0]?.paymentFrequency === "Month-to-month"
                          ? "Not required for month-to-month contracts"
                          : "",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            {/* Payment Frequency */}
            <Grid item xs={6}>
              <Autocomplete
                options={PaymentFrequency || []}
                readOnly
                value={contractData[0]?.paymentFrequency}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Payment Frequency"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Monthly Payment */}
            <Grid item xs={6}>
              <TextField
                label="Payment Amount"
                type="text"
                value={contractData[0]?.monthlyPayment}
                variant="outlined"
                fullWidth
                readOnly
                size="small"
              />
            </Grid>
            {/* Alert Users */}
            <Grid item md={12} xs={12}>
              <Autocomplete
                multiple
                options={alertUsers || []}
                getOptionLabel={(option) => option?.name}
                readOnly
                value={selectedAlertUsers || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Alert Users"
                    variant="outlined"
                    fullWidth
                    size="small"
                    readOnly
                    name="alertUsers"
                  />
                )}
              />
            </Grid>
            {/* Reminder Days */}
            <Grid item md={6} xs={12}>
              <FormLabel component="legend">Reminder Days</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedDays.includes("120")} readOnly />
                  }
                  label="120"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedDays.includes("90")} readOnly />
                  }
                  label="90"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedDays.includes("60")} readOnly />
                  }
                  label="60"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedDays.includes("30")} readOnly />
                  }
                  label="30"
                />
                <FormControlLabel
                  control={<Checkbox checked={customClick} readOnly />}
                  label="CUSTOM"
                />
              </FormGroup>
              {customClick && (
                <TextField
                  sx={{ mt: 1 }}
                  label="Days"
                  type="number"
                  fullWidth
                  size="small"
                  value={customDayValue}
                  variant="outlined"
                  readOnly
                />
              )}
            </Grid>
            {/* Notes */}
            <Grid item md={6} xs={12} sx={{ mt: 1.5 }}>
              <TextField
                id="outlined-multiline-static"
                label="Notes"
                value={contractData[0]?.notes}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            {/* Created By */}
            <Grid item md={6} xs={6}>
              <TextField
                label="Created By"
                value={contractData[0]?.userName}
                multiline
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            {/* Created By Email */}
            <Grid item md={6} xs={6}>
              <TextField
                label="Created By Email"
                value={contractData[0]?.userEmail}
                multiline
                fullWidth
                variant="outlined"
                size="small"
              />
            </Grid>
            {/* Attachments*/}
            <Grid item sx={{ mt: 1 }}>
              <Typography
                variant="h5"
                sx={{ color: "gray", marginBottom: 2, fontWeight: "bold" }}
              >
                Attchments
              </Typography>

              {files &&
                files.map((item, index) => (
                  <>
                    <Grid
                      key={index}
                      container
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography style={{ color: "grey", marginTop: "8px" }}>
                        {item.name}
                      </Typography>
                      <IconButton>
                        <Download
                          color="primary"
                          onClick={() =>
                            handleDownload(
                              item?.path.split("\\")[1], // Getting the company name from the current  file path
                              item?.email,
                              item?.name
                            )
                          }
                        />
                      </IconButton>
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid>
          <Typography variant="h5" sx={{ color: "gray", fontWeight: "bold" }}>
            Contract Detail Not Exists
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ContractDetail;
